import { Label, Table } from 'semantic-ui-react';
import { Inventory } from '@bluefox/models/Inventory';
import moment from 'moment';
import { DateFormats } from '@bluefox/models/Dates';

type InventoryListPdfProps = {
  inventories?: Inventory[];
};

const InventoryListPdf = ({ inventories }: InventoryListPdfProps) => {
  return (
    <Table striped>
      <Table.Header>
        <Table.HeaderCell>Vaccine</Table.HeaderCell>
        <Table.HeaderCell>NDC</Table.HeaderCell>
        <Table.HeaderCell>Lot</Table.HeaderCell>
        <Table.HeaderCell>Stock</Table.HeaderCell>
        <Table.HeaderCell>Expiration</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {inventories?.map((item) => {
          return <InventoryListPdfRow inventory={item} />;
        })}
      </Table.Body>
    </Table>
  );
};

//-----------------------------LIST ROW-----------------------------//

type InventoryListPdfRowProps = {
  inventory: Inventory;
};

const InventoryListPdfRow = ({ inventory }: InventoryListPdfRowProps) => {
  return (
    <Table.Row>
      <Table.Cell>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {inventory.vaccine.name}
          <Label
            basic
            size="small"
            content={inventory.vfc ? 'VFC' : 'Private'}
          />
        </div>
      </Table.Cell>
      <Table.Cell>{inventory.vaccine.saleNdc}</Table.Cell>
      <Table.Cell>{inventory.lot}</Table.Cell>
      <Table.Cell>{inventory.doses}</Table.Cell>
      <Table.Cell>
        {moment(inventory.expiration).format(DateFormats.DATE)}
      </Table.Cell>
    </Table.Row>
  );
};

export default InventoryListPdf;

import { humanizeText } from '@bluefox/lib/humanize';
import { DateFormats } from '@bluefox/models/Dates';
import { Vaccination } from '@bluefox/models/Vaccination';
import { StyledCardItem } from '@components/ScannedItemCard/styles';
import moment from 'moment';
import { useState } from 'react';
import Moment from 'react-moment';
import { Card, Grid, Icon, Popup, Table } from 'semantic-ui-react';
import EditImmunizationModal from './EditImmunizationModal';

interface ImmunizationCardChromeExtensionPops {
  visibleVaccination: Vaccination;
  selectVaccinationToDelete: (vaccinationId: string) => void;
  wasUploadedToEmr: boolean;
}

const ImmunizationCardChromeExtension = ({
  visibleVaccination,
  selectVaccinationToDelete,
  wasUploadedToEmr,
}: ImmunizationCardChromeExtensionPops) => {
  const [openEditModal, setOpenEditModal] = useState(false);

  const isRecentImmunization = () =>
    moment().diff(visibleVaccination.createdAt, 'days') === 0;

  const newImmunizationStyle = {
    backgroundColor: '#E5F9E7',
    color: '#4AC053',
    borderColor: '#1EBC30',
  };

  const renderUploadedToEmrIcon = () => {
    if (visibleVaccination.historic) return;

    if (wasUploadedToEmr) {
      return (
        <Popup
          position="top center"
          content="Uploaded to EMR"
          trigger={<Icon name="caret square up outline" color="olive" />}
        />
      );
    } else {
      return (
        <Popup
          position="top center"
          content="Not uploaded to EMR"
          trigger={<Icon name="caret square up outline" color="orange" />}
        />
      );
    }
  };

  return (
    <>
      {visibleVaccination && (
        <Card
          color="olive"
          style={{
            padding: '3px',
            fontSize: '12px',
            cursor: 'default',
            color: 'black',
          }}
        >
          <Card.Header
            style={isRecentImmunization() ? newImmunizationStyle : null}
          >
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column verticalAlign="middle" floated="left">
                  {isRecentImmunization() && (
                    <span style={{ marginLeft: '0.2rem' }}>Recent</span>
                  )}
                </Grid.Column>
                <Grid.Column
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {renderUploadedToEmrIcon()}

                  {visibleVaccination.historic ? (
                    <Popup
                      position="top center"
                      size="small"
                      content="External"
                      trigger={
                        <Icon size="small" name="info circle" color="teal" />
                      }
                    />
                  ) : null}
                  {isRecentImmunization() && (
                    <Popup
                      trigger={
                        <Icon
                          style={{ cursor: 'pointer' }}
                          name="trash"
                          size="small"
                          color="red"
                          onClick={() =>
                            selectVaccinationToDelete(
                              visibleVaccination.id || ''
                            )
                          }
                        />
                      }
                      basic
                      content="Delete"
                      size="small"
                    />
                  )}
                  <Popup
                    trigger={
                      <Icon
                        size="small"
                        name="edit"
                        color="teal"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpenEditModal(true);
                        }}
                      />
                    }
                    basic
                    content="Edit"
                    size="small"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Header>
          <Popup
            trigger={
              <div>
                <StyledCardItem>
                  {visibleVaccination.vaccine?.name}
                </StyledCardItem>
                <StyledCardItem>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontWeight: '700',
                      fontSize: '10px',
                      padding: '1px',
                      width: '100%',
                    }}
                  >
                    <span style={{ color: '#000000' }}>
                      {moment(visibleVaccination.givenAt).format('MM-DD-YYYY')}
                    </span>
                  </div>
                </StyledCardItem>
              </div>
            }
          >
            <Popup.Header>
              <Icon name="syringe" />
              {visibleVaccination.vaccine?.name}
            </Popup.Header>
            <Popup.Content>
              <Table collapsing compact size="small">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell active>Age</Table.Cell>
                    <Table.Cell>
                      {moment(
                        visibleVaccination.practicePatient?.patientData
                          ?.birthdate
                      ).format(DateFormats.DATE)}{' '}
                      (
                      <Moment
                        from={
                          visibleVaccination.practicePatient?.patientData
                            ?.birthdate
                        }
                        ago
                      ></Moment>
                      )
                    </Table.Cell>
                  </Table.Row>

                  {!!visibleVaccination.prescriber && (
                    <Table.Row>
                      <Table.Cell active>Presc</Table.Cell>
                      <Table.Cell>
                        {`${visibleVaccination.prescriber?.account.firstName} ${visibleVaccination.prescriber?.account.lastName}`}
                      </Table.Cell>
                    </Table.Row>
                  )}

                  {!!visibleVaccination.prescriber && (
                    <Table.Row>
                      <Table.Cell active>Prov</Table.Cell>
                      <Table.Cell>
                        {`${visibleVaccination.provider?.account.firstName} ${visibleVaccination.provider?.account.lastName}`}
                      </Table.Cell>
                    </Table.Row>
                  )}

                  {!!visibleVaccination.inventory?.lot && (
                    <Table.Row>
                      <Table.Cell active>Lot</Table.Cell>
                      <Table.Cell>
                        {visibleVaccination.inventory?.lot}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {!!visibleVaccination.inventory?.expiration && (
                    <Table.Row>
                      <Table.Cell active>Exp</Table.Cell>
                      <Table.Cell>
                        {moment(
                          visibleVaccination.inventory?.expiration
                        ).format(DateFormats.DATE)}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {!!visibleVaccination.site && (
                    <Table.Row>
                      <Table.Cell active>Site</Table.Cell>
                      <Table.Cell>
                        {!!visibleVaccination.site
                          ? humanizeText(visibleVaccination.site!, {
                              delimiter: '_',
                              capitalize: 'all',
                            })
                          : '-'}
                      </Table.Cell>
                    </Table.Row>
                  )}

                  {!!visibleVaccination.route && (
                    <Table.Row>
                      <Table.Cell active>Route</Table.Cell>
                      <Table.Cell>{visibleVaccination.route || '-'}</Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Popup.Content>
          </Popup>
        </Card>
      )}
      <EditImmunizationModal
        immunization={visibleVaccination}
        open={openEditModal}
        onOpen={() => setOpenEditModal(true)}
        onClose={() => setOpenEditModal(false)}
      />
    </>
  );
};

export default ImmunizationCardChromeExtension;

import { gql } from '@apollo/client';

export const InsertInventoryOrderWithInventoryMutation = gql`
  mutation InsertInventoryOrderWithInventoryMutation(
    $practiceId: uuid!
    $amount: numeric!
    $date: timestamptz!
    $doses: Int!
    $inventoryExpiration: date
    $invoiceDue: date
    $orderNumber: String
    $lot: String
    $statusOrder: order_statuses_enum!
    $statusInventory: inventory_statuses_enum!
    $statusLog: jsonb!
    $vaccineId: uuid!
    $vfc: Boolean!
  ) {
    insert_inventory_orders_one(
      object: {
        practiceId: $practiceId
        amount: $amount
        date: $date
        doses: $doses
        inventoryExpiration: $inventoryExpiration
        lot: $lot
        status: $statusOrder
        statusLog: $statusLog
        vaccineId: $vaccineId
        vfc: $vfc
        inventory: {
          data: {
            practiceId: $practiceId
            vaccineId: $vaccineId
            lot: $lot
            expiration: $inventoryExpiration
            status: $statusInventory
            doses: $doses
            vfc: $vfc
          }
        }
      }
    ) {
      id
    }
  }
`;

export const BorrowingInventoryAdjustments = gql`
  query BorrowingInventoryAdjustments(
    $criteria: inventory_adjustments_bool_exp
  ) {
    adjustments: inventory_adjustments(
      where: $criteria
      order_by: { createdAt: asc }
    ) {
      id
      createdAt
      statusLog
    }
    aggregating: inventory_adjustments_aggregate(where: $criteria) {
      aggregate {
        count
      }
    }
  }
`;

export const BorrowingInventoryAdjustmentsDetails = gql`
  query BorrowingInventoryAdjustmentsDetails(
    $criteria: inventory_adjustment_details_bool_exp
  ) {
    details: inventory_adjustment_details(
      where: $criteria
      order_by: { inventory_adjustment: { vfc: asc }, type: asc }
    ) {
      id
      currentDoses
      newDoses
      type
      inventory {
        vaccine {
          name
          types
          saleNdc
        }
        lot
        vfc
      }
    }
  }
`;

export const BorrowingSummaryPdfExportQuery = gql`
  query BorrowingSummaryPdfExportQuery(
    $criteria: inventory_adjustment_details_bool_exp
    $redundantCriteria: billing_claim_vfc_inconsistencies_bool_exp
  ) {
    details: inventory_adjustment_details(
      where: $criteria
      order_by: { inventory_adjustment: { vfc: asc }, type: asc }
    ) {
      id
      currentDoses
      newDoses
      type
      inventory {
        vaccine {
          name
          types
          saleNdc
        }
        lot
        vfc
      }
      targetClaimVfcInconsistencies {
        createdAt
        id
        practiceId
        practice {
          id
          name
        }
        claimId
        inventoryId
        vaccinationId
        inventory {
          id
          expiration
          vfc
          lot
          doses
          vaccine {
            id
            name
            aka
            types
            saleNdc
          }
        }
        type
        status
        claimUpdatesId
        claim {
          id
          givenAt
          insurance: insuranceForPracticePortal {
            insuranceCompanyName
            insuranceCompanyId
            memberId
            vfcEligible
            vfcCriteria
            payerId
          }
          practicePatient {
            patientData {
              birthdate
              firstName
              lastName
            }
          }
        }
      }
      sourceClaimVfcInconsistencies {
        createdAt
        id
        practiceId
        practice {
          id
          name
        }
        claimId
        inventoryId
        vaccinationId
        inventory {
          id
          expiration
          vfc
          lot
          doses
          vaccine {
            id
            name
            aka
            types
            saleNdc
          }
        }
        type
        status
        claimUpdatesId
        claim {
          id
          givenAt
          insurance: insuranceForPracticePortal {
            insuranceCompanyName
            insuranceCompanyId
            memberId
            vfcEligible
            vfcCriteria
            payerId
          }
          practicePatient {
            patientData {
              birthdate
              firstName
              lastName
            }
          }
        }
      }
    }
    redundants: billing_claim_vfc_inconsistencies(
      where: $redundantCriteria
      order_by: { inventory: { vfc: asc }, createdAt: desc }
    ) {
      borrowingReasonCode
      borrowingReasonCodeOtherDescription
      claim {
        insurance: insuranceForPracticePortal {
          insuranceCompanyName
          insuranceCompanyId
          memberId
          vfcEligible
          vfcCriteria
          payerId
        }
        practicePatient {
          patientData {
            firstName
            lastName
            birthdate
          }
        }
        givenAt
      }
      inventory {
        vfc
        vaccine {
          saleNdc
          name
        }
        lot
      }
      status
      redundantWith {
        id
        inventory {
          vfc
          vaccine {
            name
          }
          lot
        }
        claim {
          insurance: insuranceForPracticePortal {
            insuranceCompanyName
            memberId
            vfcEligible
          }
          practicePatient {
            patientData {
              firstName
              lastName
              birthdate
            }
          }
          givenAt
        }
      }
    }
  }
`;

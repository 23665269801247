import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Detail } from './BorrowingSummaryDetails';

// Define styles for PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  table: {
    width: 'auto',
    borderStyle: 'solid',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '14.28%',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 8,
    padding: 2,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'center',
  },
  subHeader: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 6,
    margin: 'auto',
  },
  types: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '5px',
  },
  label: {
    fontSize: 6,
    padding: 2,
  },
  claimsTable: {
    width: '100%',
    borderStyle: 'solid',
    marginTop: 5,
    marginBottom: 5,
  },
  claimsRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  claimsCol: {
    width: '16.66%',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  claimsCell: {
    margin: 'auto',
    marginTop: 2,
    fontSize: 6,
    padding: 1,
  },
});

interface BorrowingSummaryPDFProps {
  borrowingData: Detail[] | undefined;
}

const BorrowingSummaryPDF = ({ borrowingData }: BorrowingSummaryPDFProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>Borrowing Summary</Text>
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Inventory Returned</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Returned Current Doses</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Returned New Doses</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Inventory Recovered</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Recovered Current Doses</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Recovered New Doses</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Claims</Text>
            </View>
          </View>
          {/* Table Body */}
          {borrowingData?.map((item, index: number) => {
            return (
              <React.Fragment key={index}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {item.inventory.vaccine.name}
                    </Text>
                    <Text style={styles.subHeader}>
                      {item?.inventory?.vaccine?.saleNdc || '-'}
                    </Text>
                    {item.inventory.vaccine.types.map((type, i: number) => (
                      <Text key={i} style={styles.label}>
                        {type}
                      </Text>
                    ))}
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {item.returnedCurrentDoses}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {item.returnedNewDoses}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {item.inventory.vaccine.name}
                    </Text>
                    <Text style={styles.subHeader}>
                      {item?.inventory?.vaccine?.saleNdc || '-'}
                    </Text>
                    {item.inventory.vaccine.types.map((type, i: number) => (
                      <Text key={i} style={styles.label}>
                        {type}
                      </Text>
                    ))}
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {item.recoveredCurrentDoses}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {item.recoveredNewDoses}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>See below</Text>
                  </View>
                </View>
                {/* Claims Table */}
                <View style={styles.claimsTable}>
                  <View style={styles.claimsRow}>
                    <View style={styles.claimsCol}>
                      <Text style={styles.claimsCell}>Patient</Text>
                    </View>
                    <View style={styles.claimsCol}>
                      <Text style={styles.claimsCell}>Insurance</Text>
                    </View>
                    <View style={styles.claimsCol}>
                      <Text style={styles.claimsCell}>Service Date</Text>
                    </View>
                    <View style={styles.claimsCol}>
                      <Text style={styles.claimsCell}>
                        Inventory Used / NDC
                      </Text>
                    </View>
                    <View style={styles.claimsCol}>
                      <Text style={styles.claimsCell}>Vaccine / Lot</Text>
                    </View>
                  </View>
                  {item.targetClaimVfcInconsistencies.map(
                    (claim, claimIndex: number) => (
                      <View key={claimIndex} style={styles.claimsRow}>
                        <View style={styles.claimsCol}>
                          <Text
                            style={styles.claimsCell}
                          >{`${claim.claim.practicePatient.patientData.lastName}, ${claim.claim.practicePatient.patientData.firstName} (${claim.claim.practicePatient.patientData.birthdate})`}</Text>
                        </View>
                        <View style={styles.claimsCol}>
                          <Text style={styles.claimsCell}>
                            {claim.claim.insurance[0].insuranceCompanyName}
                          </Text>
                        </View>
                        <View style={styles.claimsCol}>
                          <Text style={styles.claimsCell}>
                            {claim.claim.givenAt}
                          </Text>
                        </View>
                        <View style={styles.claimsCol}>
                          <Text style={styles.claimsCell}>
                            {claim.inventory.vfc ? 'VFC Eligible' : 'Private'}
                          </Text>
                          <Text style={styles.claimsCell}>
                            {claim.inventory.vaccine.saleNdc}
                          </Text>
                        </View>
                        <View style={styles.claimsCol}>
                          <Text style={styles.claimsCell}>
                            {claim.inventory.vaccine.name}
                          </Text>
                          <Text style={styles.claimsCell}>
                            {claim.inventory.lot}
                          </Text>
                        </View>
                      </View>
                    )
                  )}
                </View>
              </React.Fragment>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default BorrowingSummaryPDF;

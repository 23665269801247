import React, { useEffect, useRef, useState } from 'react';
import { Form, Message, Radio, Table } from 'semantic-ui-react';
import { BorrowedVaccinesTableRow } from './BorrowedVaccinesTableRow';
import { GetClaimsVFCInconsistencies } from '@bluefox/graphql/billing';
import { useLazyQuery, useQuery } from '@apollo/client';
import { BorrowingInventoryAdjustments } from '@graphql/inventory';
import VaccinePicker from '@bluefox/ui/VaccinePicker';
import { Vaccine } from '@bluefox/models/Vaccine';
import DateTimePicker from '@bluefox/ui/DateTimePicker';
import { DateFormats } from '@bluefox/models/Dates';
import { whereLikeInput } from '@bluefox/graphql/utils';
import { debounce } from '@bluefox/lib/debounce';
import { VFCInconsistency } from '@bluefox/models/VFCInconsistency';
import { usePractice } from '@bluefox/contexts';

interface BorrowedVaccinesTableProps {
  practiceId: string;
  setArrayOfRedundantIds: (idArray: string[]) => void;
}

interface Ticket {
  id: string;
  detail: {
    fields: Field[];
  };
}

interface Field {
  title: string;
  detail: string;
}

interface RedundantCycleCondition {
  status?: {
    _eq: string;
  };
}

interface RedundantCycleWithAnd {
  _and: [
    {
      status?: {
        _eq: string;
      };
    },
    {
      updatedAt?: {
        _gte: string;
      };
    },
  ];
}

type RedundantCycle = RedundantCycleCondition | RedundantCycleWithAnd;

export const MagicWordsDict = {
  STATUS: {
    APPLIED: 'applied',
    PENDING: 'pending',
    REDUNDANT: 'redundant',
  },
  TYPE: {
    BORROW: 'borrow',
  },
  REASON: {
    BORROWING: 'borrowing',
  },
  BOOLEAN: {
    TRUE: true,
  },
};

export const BorrowedVaccinesTable = ({
  practiceId,
  setArrayOfRedundantIds,
}: BorrowedVaccinesTableProps) => {
  const [ticketsIdsArray, setTicketsIdsArray] = useState<string[]>([]);
  const [borrowingPending, setBorrowingPending] = useState<VFCInconsistency[]>(
    []
  );
  const [borrowingWithTickets, setBorrowingWithTickets] = useState<
    VFCInconsistency[]
  >([]);
  const [borrowingRedundant, setBorrowingRedundant] = useState<
    VFCInconsistency[]
  >([]);
  const [redundantCycle, setRedundantCycle] = useState<RedundantCycle>();
  const [patient, setPatient] = useState('');
  const [patientValue, setPatientValue] = useState('');
  const [insurance, setInsurance] = useState('');
  const [insuranceValue, setInsuranceValue] = useState('');
  const [dateOfService, setDateOfService] = useState<Date>();
  const [vaccine, setVaccine] = useState<Vaccine>();
  const [lot, setLot] = useState('');
  const [lotValue, setLotValue] = useState('');
  const [showRedundantReadyToSwap, setShowRedundantReadyToSwap] =
    useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState('all');

  const { profile } = usePractice();
  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  const [getVFCInconsistencies] = useLazyQuery(
    GetClaimsVFCInconsistencies(true),
    {
      onCompleted(data) {
        const tickets = data.tickets.flatMap((item: Ticket) =>
          item.detail.fields
            .filter((field) => field.title === 'VfcInconsistencyId')
            .map((field) => field.detail)
        );
        setTicketsIdsArray(tickets);
        const borrowWithTickets = data.vfcInconsistencies.filter(
          (inconsistency: VFCInconsistency) =>
            inconsistency.status === MagicWordsDict.STATUS.PENDING &&
            tickets.includes(inconsistency.id)
        );

        const borrowPending = data.vfcInconsistencies.filter(
          (inconsistency: VFCInconsistency) =>
            inconsistency.status === MagicWordsDict.STATUS.PENDING &&
            !tickets.includes(inconsistency.id)
        );
        const borrowRedundant = data.vfcInconsistencies.filter(
          (inconsistency: VFCInconsistency) =>
            inconsistency.status === MagicWordsDict.STATUS.REDUNDANT
        );
        const redundantsArrayId = data.vfcInconsistencies
          .filter(
            (inconsistency: VFCInconsistency) =>
              inconsistency.status === MagicWordsDict.STATUS.REDUNDANT &&
              inconsistency.readyToBeSwapped === MagicWordsDict.BOOLEAN.TRUE &&
              inconsistency.swappedDate === undefined
          )
          .map((inconsistency: VFCInconsistency) => inconsistency.id);
        setBorrowingWithTickets([...borrowWithTickets]);
        setBorrowingPending([...borrowPending]);
        setBorrowingRedundant([...borrowRedundant]);
        setArrayOfRedundantIds(redundantsArrayId);
      },
    }
  );

  useQuery(BorrowingInventoryAdjustments, {
    variables: {
      criteria: {
        inventoryAdjustmentDetails: {
          reason: {
            _eq: MagicWordsDict.REASON.BORROWING,
          },
        },
        status: {
          _eq: MagicWordsDict.STATUS.APPLIED,
        },
        practice: {
          id: {
            _eq: practiceId,
          },
        },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      const _redundantCycle =
        data.adjustments.length > 0
          ? {
              _and: [
                {
                  status: {
                    _eq: MagicWordsDict.STATUS.REDUNDANT,
                  },
                },
                {
                  updatedAt: { _gte: data.adjustments[0].createdAt },
                },
              ],
            }
          : {
              status: {
                _eq: MagicWordsDict.STATUS.REDUNDANT,
              },
            };
      setRedundantCycle(_redundantCycle);
    },
  });

  useEffect(() => {
    getVFCInconsistencies({
      variables: {
        criteria: {
          practiceId: {
            _eq: practiceId,
          },
          readyToBeSwapped: statusFilter === 'all' ? {} : { _eq: statusFilter },
          swappedDate: { _is_null: true },
          claim: {
            insuranceForPracticePortal: {
              insuranceCompanyName: {
                _ilike: whereLikeInput(insurance),
              },
            },
            practicePatient: {
              patientData: {
                fullName: { _ilike: whereLikeInput(patient) },
              },
            },
            givenAt: dateOfService ? { _eq: dateOfService } : {},
          },
          inventory: {
            lot: {
              _ilike: whereLikeInput(lot),
            },
            vaccine: {
              id: vaccine ? { _eq: vaccine.id } : {},
            },
          },
          _or: [
            {
              status: {
                _eq: MagicWordsDict.STATUS.PENDING,
              },
            },
            {
              ...redundantCycle,
            },
          ],
          type: {
            _eq: MagicWordsDict.TYPE.BORROW,
          },
        },
      },
    });
  }, [
    vaccine,
    patient,
    lot,
    insurance,
    dateOfService,
    redundantCycle,
    statusFilter,
  ]);

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <>
      <Form borderless>
        <Form.Group>
          <Form.Field>
            <label>Patient</label>
            <Form.Input
              placeholder={'Search by patient'}
              value={patientValue}
              onChange={(e, { value }) => {
                setPatientValue(value);
                debouncedRef.current?.cancel();
                debouncedRef.current = debounce(() => {
                  setPatient(value);
                }, 350);

                debouncedRef.current();
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Insurance</label>
            <Form.Input
              placeholder={'Search by insurance'}
              value={insuranceValue}
              onChange={(e, { value }) => {
                setInsuranceValue(value);
                debouncedRef.current?.cancel();
                debouncedRef.current = debounce(() => {
                  setInsurance(value);
                }, 350);

                debouncedRef.current();
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Date of Service</label>
            <DateTimePicker
              placeholderText="Date of Service"
              selected={dateOfService}
              onChange={(d) => {
                setDateOfService(d ? (d as Date) : undefined);
              }}
              onSelect={(value) =>
                setDateOfService(value ? (value as Date) : undefined)
              }
              onClear={() => setDateOfService(undefined)}
              maxDate={new Date()}
              dateFormat={DateFormats.DATE}
              showYearDropdown
              showMonthDropdown
              scrollableYearDropdown
              dropdownMode="select"
              isClearable
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Vaccine</label>
            <VaccinePicker
              placeholder={'Search by vaccine'}
              dropdownProps={{
                placeholder: 'Filter by Vaccine',
                clearable: true,
              }}
              onChange={({ vaccine }) => setVaccine(vaccine)}
              value={vaccine?.id}
            />
          </Form.Field>
          <Form.Field>
            <label>Lot</label>
            <Form.Input
              placeholder={'Search by lot number'}
              value={lotValue}
              onChange={(e, { value }) => {
                setLotValue(value);
                debouncedRef.current?.cancel();
                debouncedRef.current = debounce(() => {
                  setLot(value);
                }, 350);

                debouncedRef.current();
              }}
            />
          </Form.Field>
          <Form.Field>
            <Form.Dropdown
              label="Filter by Status"
              style={{ minWidth: '15rem' }}
              placeholder="Filter by status"
              fluid
              value={statusFilter}
              selection
              onChange={(e, { value }) => {
                setStatusFilter(value as string);
              }}
              options={[
                {
                  text: `${'All status'}`,
                  value: 'all',
                },
                {
                  text: `${'Ready to Swap'}`,
                  value: true,
                },
                {
                  text: `${'NOT Ready to Swap'}`,
                  value: false,
                },
              ]}
            />
          </Form.Field>
          <Form.Field>
            <label>Show Redundant Ready to Swap:</label>
            <Radio
              toggle
              checked={showRedundantReadyToSwap}
              onChange={(_, { checked }) =>
                setShowRedundantReadyToSwap(!!checked)
              }
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
              Patient
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
              Insurance
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Date Of Service
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Inventory Used <hr /> NDC
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Vaccine <hr /> Lot
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="2" width={1} textAlign="center">
              Redundant With
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Borrowing Report Code
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
              Review
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {borrowingWithTickets.length > 0 ||
        borrowingPending.length > 0 ||
        borrowingRedundant.length > 0 ? (
          <Table.Body>
            {borrowingWithTickets.map((inconsistency) => {
              const withTicket = ticketsIdsArray.includes(
                inconsistency.id as string
              );
              return (
                <BorrowedVaccinesTableRow
                  inconsistency={inconsistency}
                  activeOpenTicket={withTicket}
                />
              );
            })}
            {borrowingPending.map((inconsistency) => (
              <BorrowedVaccinesTableRow inconsistency={inconsistency} />
            ))}
            {borrowingRedundant.map((inconsistency) => {
              if (showRedundantReadyToSwap) {
                return (
                  <BorrowedVaccinesTableRow inconsistency={inconsistency} />
                );
              } else if (!showRedundantReadyToSwap) {
                if (!inconsistency.readyToBeSwapped) {
                  return (
                    <BorrowedVaccinesTableRow inconsistency={inconsistency} />
                  );
                }
              }
            })}
          </Table.Body>
        ) : (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan={10}>
                <Message>There are no borrowed cases found.</Message>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        )}
      </Table>
    </>
  );
};

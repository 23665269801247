import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Header, Label, Message, Table } from 'semantic-ui-react';
import { BorrowingSummaryPdfExportQuery } from '@graphql/inventory';
import { addDays } from '@bluefox/lib/formatters';
import { MagicWordsDict } from './BorrowedVaccinesTable';
import { PDFDownloadLink } from '@react-pdf/renderer';
import BorrowingSummaryPDF from './BorrowingSummaryPdfExport';
import {
  VFCInconsistency,
  VfcInconsistencyInsurance,
} from '@bluefox/models/VFCInconsistency';
import { BorrowedVaccinesTableRow } from './BorrowedVaccinesTableRow';

interface BorrowingSummaryDetailsProps {
  date: string;
  viewChange: (activeIndex: number, date?: string, id?: string) => void;
  practiceId: string;
}

export interface Option {
  value: string;
  comment: string;
}

type Inconsistency = {
  id: string;
  createdAt: string;
  practiceId: string;
  practice: {
    id: string;
    name: string;
  };
  claimId: string;
  inventoryId: string;
  vaccinationId: string;
  inventory: {
    id: string;
    expiration: string;
    vfc: boolean;
    lot: string;
    doses: number;
    vaccine: {
      id: string;
      name: string;
      aka: string;
      types: string[];
      saleNdc: string;
    };
  };
  type: string;
  status: string;
  redundantWithId: string | null;
  redundantWith: string | null;
  claimUpdatesId: string;
  claim: {
    id: string;
    givenAt: string;
    insurance: VfcInconsistencyInsurance[];
    practicePatient: {
      patientData: {
        birthdate: string;
        firstName: string;
        lastName: string;
      };
    };
    updates: {
      claimId: string;
      id: string;
      status: string;
      insuranceName: string;
      insuranceCompanyId: string;
      memberId: string;
      vfcEligible: boolean;
      vfcCriteria: string | null;
      payerId: string;
    }[];
  };
};

export type Detail = {
  id: string;
  currentDoses: number;
  newDoses: number;
  type: string;
  inventory: {
    vaccine: {
      name: string;
      types: string[];
      saleNdc: string | null;
    };
    lot: string;
    vfc: boolean;
  };
  returnedCurrentDoses: number;
  returnedNewDoses: number;
  recoveredCurrentDoses: number;
  recoveredNewDoses: number;
  targetClaimVfcInconsistencies: Inconsistency[];
  sourceClaimVfcInconsistencies: Inconsistency[];
};

type Data = {
  details: Detail[];
};

const areVaccineTypesEqual = (types1: string[], types2: string[]): boolean => {
  return (
    types1.length === types2.length &&
    types1.every((type) => types2.includes(type))
  );
};

export const mergeInconsistencies = (data: Data) => {
  const mergedDetails = data.details.map((detail) => {
    const sourceClaimIds = detail.sourceClaimVfcInconsistencies.map(
      (inc) => inc.claimId
    );

    // Find a matching detail based on claimId arrays
    const matchingDetail = data.details.find(
      (otherDetail) =>
        otherDetail !== detail &&
        JSON.stringify(
          otherDetail.targetClaimVfcInconsistencies
            .map((inc) => inc.claimId)
            .sort()
        ) === JSON.stringify(sourceClaimIds.sort()) &&
        areVaccineTypesEqual(
          otherDetail.inventory.vaccine.types,
          detail.inventory.vaccine.types
        ) &&
        otherDetail.targetClaimVfcInconsistencies.every((inc) =>
          areVaccineTypesEqual(
            inc.inventory.vaccine.types,
            detail.inventory.vaccine.types
          )
        )
    );

    if (matchingDetail) {
      // Merge the sourceClaimVfcInconsistencies into the matching detail's targetClaimVfcInconsistencies
      const mergedInconsistencies = [
        ...matchingDetail.targetClaimVfcInconsistencies,
        ...detail.sourceClaimVfcInconsistencies,
      ];

      // Remove duplicates based on id
      const uniqueInconsistencies = mergedInconsistencies.filter(
        (inc, index, self) => index === self.findIndex((t) => t.id === inc.id)
      );

      // Merge inventory information
      const mergedInventory = {
        ...matchingDetail.inventory,
        ...detail.inventory,
        vaccine: {
          ...matchingDetail.inventory.vaccine,
          ...detail.inventory.vaccine,
          types: matchingDetail.inventory.vaccine.types,
        },
      };

      // Differentiate currentDoses and newDoses
      const mergedDoses = {
        [`${matchingDetail.type}CurrentDoses`]: matchingDetail.currentDoses,
        [`${matchingDetail.type}NewDoses`]: matchingDetail.newDoses,
        [`${detail.type}CurrentDoses`]: detail.currentDoses,
        [`${detail.type}NewDoses`]: detail.newDoses,
      };

      return {
        ...matchingDetail,
        ...mergedDoses,
        inventory: mergedInventory,
        targetClaimVfcInconsistencies: uniqueInconsistencies,
        sourceClaimVfcInconsistencies: [],
      } as Detail;
    } else {
      return detail;
    }
  });

  // Remove duplicates and empty details
  const uniqueMergedDetails = mergedDetails
    .filter(
      (detail, index, self) =>
        index === self.findIndex((t) => t.id === detail.id)
    )
    .filter(
      (detail) =>
        detail.targetClaimVfcInconsistencies.length > 0 ||
        detail.sourceClaimVfcInconsistencies.length > 0
    );

  return uniqueMergedDetails;
};

export const BorrowingSummaryDetails = ({
  viewChange,
  date,
  practiceId,
}: BorrowingSummaryDetailsProps) => {
  const [details, setDetails] = useState<Detail[]>();
  const [redundants, setRedundants] = useState<VFCInconsistency[]>([]);

  useQuery(BorrowingSummaryPdfExportQuery, {
    variables: {
      criteria: {
        inventory_adjustment: {
          _and: [
            { createdAt: { _gte: new Date(date) } },
            {
              createdAt: {
                _lte: addDays(new Date(date), 1),
              },
            },
          ],
          status: { _eq: MagicWordsDict.STATUS.APPLIED },
          practiceId: { _eq: practiceId },
        },
        reason: { _eq: MagicWordsDict.REASON.BORROWING },
      },
      redundantCriteria: {
        _and: [
          {
            swappedDate: { _gte: new Date(date) },
          },
          {
            swappedDate: {
              _lte: addDays(new Date(date), 1),
            },
          },
        ],
      },
    },
    onCompleted(datax) {
      setDetails(mergeInconsistencies(datax));
      setRedundants(datax.redundants);
    },
  });

  return (
    <>
      <PDFDownloadLink
        document={<BorrowingSummaryPDF borrowingData={details} />}
        fileName="Borrowing-summary.pdf"
      >
        {() => (
          <Button
            color="blue"
            inverted
            icon="download"
            content={'Download PDF'}
            disabled={!details?.length}
          />
        )}
      </PDFDownloadLink>
      <Table celled structured selectable>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>Inventory used</Table.HeaderCell>
            <Table.HeaderCell>Doses</Table.HeaderCell>
            <Table.HeaderCell>Inventory recovered from</Table.HeaderCell>
            <Table.HeaderCell>Claims</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {details ? (
            details.map((detail: Detail) => {
              return (
                <Table.Row key={detail.id} textAlign="center">
                  <Table.Cell>
                    <Header>
                      {
                        detail.targetClaimVfcInconsistencies[0]?.inventory
                          ?.vaccine?.name
                      }
                      <Label
                        content={
                          detail.targetClaimVfcInconsistencies[0]?.inventory
                            ?.vfc
                            ? 'VFC'
                            : 'Private'
                        }
                        size="tiny"
                        color={
                          detail.targetClaimVfcInconsistencies[0]?.inventory
                            ?.vfc
                            ? 'orange'
                            : 'teal'
                        }
                      />
                      <Header.Subheader>
                        {detail.targetClaimVfcInconsistencies[0]?.inventory?.vaccine?.types?.map(
                          (type: string) => (
                            <Label key={type} color="black" size="mini">
                              {type}
                            </Label>
                          )
                        )}
                      </Header.Subheader>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    {detail?.returnedCurrentDoses - detail?.returnedNewDoses}
                  </Table.Cell>
                  <Table.Cell>
                    <Header>
                      {detail?.inventory?.vaccine?.name}
                      <Label
                        content={detail?.inventory?.vfc ? 'VFC' : 'Private'}
                        size="tiny"
                        color={detail?.inventory?.vfc ? 'orange' : 'teal'}
                      />
                      <Header.Subheader>
                        Lot: {detail?.inventory?.lot || '-'} <br />
                        {detail?.inventory?.vaccine?.types?.map(
                          (type: string) => (
                            <Label key={type} color="black" size="mini">
                              {type}
                            </Label>
                          )
                        )}
                      </Header.Subheader>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      primary
                      className="min-component-vertical-margin"
                      icon="eye"
                      size="tiny"
                      onClick={() => viewChange(2, '', detail?.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Cell colSpan={5}>
              <Message>No details found for this adjustment.</Message>
            </Table.Cell>
          )}
        </Table.Body>
      </Table>
      {redundants?.length > 0 && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Patient
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Insurance
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Date Of Service
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Inventory Used <hr /> NDC
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Vaccine <hr /> Lot
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2" width={1} textAlign="center">
                Redundant With
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Borrowing Report Code
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {redundants.map((inconsistency) => (
              <BorrowedVaccinesTableRow inconsistency={inconsistency} summary />
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

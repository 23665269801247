import React, { useState } from 'react';
import { PDFPage, PDFDocument, rgb } from 'pdf-lib';
import moment from 'moment-timezone';
import { PatientData } from '@bluefox/models/Patient';
import { Vaccination } from '@bluefox/models/Vaccination';
import { vaccinationsByDosesCoordsMap } from './vaccinationCoords';
import { useApplicationState } from '@bluefox/contexts';
import PdfDrawerPreviewModal from '@utils/PdfDrawerPreviewModal';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters';

const twoDosesTypes = ['VARICELLA'];

const threeDosesTypes = ['MMR', 'MEASLES', 'MUMPS', 'RUBELLA', 'MENING'];

const sixDosesTypes = [
  'TDAP',
  'POLIO',
  'HepB',
  'MeningB',
  'HPV',
  'FLU',
  'COVID-19',
];

type ColoradoCertificateOfImmunizationForCollegePdfProps = {
  patientData?: PatientData;
  vaccinations?: Vaccination[];
  pageBlurred?: boolean;
};

const ColoradoCertificateOfImmunizationForCollegePdf = ({
  patientData,
  vaccinations,
  pageBlurred,
}: ColoradoCertificateOfImmunizationForCollegePdfProps) => {
  const { isEmbedded } = useApplicationState();

  const [pdfURL, setPdfURL] = useState('');
  const [openPdfPreviewModal, setOpenPdfPreviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  //----------- DRAW PATIENT INFO PAGE 1 -----------//
  const drawPatientInfoInPage1 = (page: PDFPage, patientData?: PatientData) => {
    // Patient's full name
    page.drawText(
      patientData ? `${patientData.lastName}, ${patientData.firstName}` : '',
      {
        x: 115,
        y: 637,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );

    // Patient's birthdate
    page.drawText(
      patientData ? moment(patientData.birthdate).format('MM/DD/YYYY') : '',
      {
        x: 510,
        y: 637,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );
  };

  //----------- DRAW VACCINATION DOSES -----------//
  const drawDoses = (page: PDFPage, coords: any, givenAt: string) => {
    page.drawText(formatDateToMMDDYYYYV2(givenAt), {
      x: coords.x,
      y: coords.y,
      size: 8,
      color: rgb(0, 0, 0),
    });
  };

  const limitDosesByType = (
    typesArr: string[],
    type: string | number,
    vaccinations: any,
    quantity: number
  ) => {
    if (
      typesArr.some((item) => item === type) &&
      vaccinations.groupedTypes[type].length >= quantity
    ) {
      vaccinations.groupedTypes[type].shift();
    }
  };

  const drawVaccinations = (
    page: PDFPage,
    vaccinations: Vaccination[] | undefined
  ) => {
    const groupedVaccinationsByType = vaccinations?.reduce(
      (vaccinationsObject, vaccination) => {
        for (const key in vaccination.vaccine?.types) {
          const type = vaccination.vaccine?.types[parseInt(key)] || 0;

          if (!vaccinationsObject.groupedTypes[type]) {
            vaccinationsObject.groupedTypes[type] = [];
          }

          // If the type already has 7 vaccinations, remove the oldest one (the first element)
          if (vaccinationsObject.groupedTypes[type].length >= 7) {
            vaccinationsObject.groupedTypes[type].shift();
          }

          // If the type is included in the twoDosesTypes array and already has 2 vaccinations, remove the oldest one and show 2 doses
          limitDosesByType(twoDosesTypes, type, vaccinationsObject, 2);
          // If the type is included in the threeDosesTypes array and already has 3 vaccinations, remove the oldest one and show 3 doses
          limitDosesByType(threeDosesTypes, type, vaccinationsObject, 3);
          // If the type is included in the sixDosesTypes array and already has 6 vaccinations, remove the oldest one and show 6 doses
          limitDosesByType(sixDosesTypes, type, vaccinationsObject, 6);

          vaccinationsObject.groupedTypes[type].push(vaccination);
        }

        return vaccinationsObject;
      },
      { groupedTypes: {} } as any
    );

    for (const type in groupedVaccinationsByType.groupedTypes) {
      const vaccinations = groupedVaccinationsByType.groupedTypes[type];

      vaccinations.forEach((vaccination: Vaccination, index: number) => {
        const givenAt = vaccination.givenAt;

        const vaccinationCoordsByTypes = vaccinationsByDosesCoordsMap[type];

        if (!vaccinationCoordsByTypes) return;

        const pdfCoords = vaccinationCoordsByTypes[index];

        drawDoses(
          page,
          pdfCoords,
          vaccination.historic
            ? givenAt.toString()
            : moment(givenAt).format('MM/DD/YY')
        );
      });
    }
  };

  //----------- DRAW PATIENT INFO PAGE 2 -----------//
  const drawPatientInfoInPage2 = (
    page: PDFPage,
    patientData: PatientData | undefined
  ) => {
    // Patient's full name
    page.drawText(
      patientData ? `${patientData.lastName}, ${patientData.firstName}` : '',
      {
        x: 172,
        y: 88,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );

    // Patient's birthdate (month)
    page.drawText(
      patientData ? moment(patientData.birthdate).format('MM') : '',
      {
        x: 449,
        y: 95,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );

    // Patient's birthdate (day)
    page.drawText(
      patientData ? moment(patientData.birthdate).format('DD') : '',
      {
        x: 470,
        y: 95,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );

    // Patient's birthdate (year)
    page.drawText(
      patientData ? moment(patientData.birthdate).format('YYYY') : '',
      {
        x: 488,
        y: 95,
        size: 10,
        color: rgb(0, 0, 0),
      }
    );
  };

  const downloadModifiedPDF = async () => {
    // const urlPDF1 = process.env
    //   .REACT_APP_COLORADO_CERTIFICATE_OF_IMMUNIZATION_FOR_COLLEGE_FORM_PAGE_1_URL as string;
    // const urlPDF2 = process.env
    //   .REACT_APP_COLORADO_CERTIFICATE_OF_IMMUNIZATION_FOR_COLLEGE_FORM_PAGE_2_URL as string;

    const urlPDF1 = `${process.env.PUBLIC_URL}/forms/colorado/CO_College_Certificate-pages-1.pdf`;
    const urlPDF2 = `${process.env.PUBLIC_URL}/forms/colorado/CO_College_Certificate-pages-2.pdf`;

    try {
      const pdfDoc = await PDFDocument.create();

      const pages = await Promise.all([
        fetch(urlPDF1)
          .then((res) => res.arrayBuffer())
          .then((bytes) => PDFDocument.load(bytes))
          .then(async (pdf1Doc) => {
            const [page1] = await pdfDoc.copyPages(pdf1Doc, [0]);
            drawPatientInfoInPage1(page1, patientData);
            drawVaccinations(page1, vaccinations);
            return page1;
          }),
        fetch(urlPDF2)
          .then((res) => res.arrayBuffer())
          .then((bytes) => PDFDocument.load(bytes))
          .then(async (pdf2Doc) => {
            const [page2] = await pdfDoc.copyPages(pdf2Doc, [0]);
            drawPatientInfoInPage2(page2, patientData);
            return page2;
          }),
      ]);

      pages.forEach((page) => pdfDoc.addPage(page));
      setLoading(false);

      const modifiedPdfBytes = await pdfDoc.save();

      const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
        type: 'application/pdf',
      });
      const downloadUrl = URL.createObjectURL(modifiedPdfBlob);

      // Crea una URL para la vista previa del archivo PDF modificado
      return setPdfURL(downloadUrl);
    } catch (error) {
      setError(`Error while processing the PDF. ${error}`);
    }
  };

  return (
    <PdfDrawerPreviewModal
      buttonLabel="College/University students"
      onClickMenuButton={() => {
        setLoading(true);
        setOpenPdfPreviewModal(true);
        downloadModifiedPDF();
      }}
      open={openPdfPreviewModal}
      onClose={() => {
        setOpenPdfPreviewModal(false);
      }}
      pdfURL={pdfURL}
      isEmbedded={isEmbedded}
      error={error}
      pageBlurred={pageBlurred}
      loading={loading}
      isDropdownItem
    />
  );
};

export default ColoradoCertificateOfImmunizationForCollegePdf;

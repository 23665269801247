import { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import { useQuery, useMutation } from '@apollo/client';
import FullLayout from '@components/FullLayout';
import { PatientDataProvider } from '@bluefox/contexts/PatientData';
import { PracticePatientProvider } from '@bluefox/contexts/PracticePatientProvider';

import {
  PracticePatientProfileQuery,
  SyncImmunizationsMutation,
} from '@bluefox/graphql/patients';
//import { VerifyInsuranceMutation } from "@bluefox/graphql/insurances";
import {
  InconsistentVaccinationsByPatientQuery,
  RetrieveAthenaPatientImmunizationsMutation,
  VaccinationsByPracticePatientQuery,
} from '@bluefox/graphql/vaccinations';

import { PracticePatient, PatientData } from '@bluefox/models/Patient';
import { Appointment } from '@bluefox/models/Appointment';
import { Vaccination } from '@bluefox/models/Vaccination';
import { Insurance, VfcCriteria } from '@bluefox/models/Insurances';
import { Route, useParams } from 'react-router-dom';
import {
  Header,
  Button,
  Segment,
  Label,
  Grid,
  Icon,
  Modal,
  Message,
  Placeholder,
  Popup,
  Menu,
} from 'semantic-ui-react';
import { getAge } from '@bluefox/lib/getAge';

import PatientMenuButton from '@components/PatientMenuButton';
import Scanner from './Scan';
import VaccineCalendarWidget from '@components/VaccineCalendarWidget';
import { AppointmentBar } from '@components/AppointmentWidgets';
import InsurancesForm from '@bluefox/ui/InsurancesForm';
import VaxHistoryModal from '@components/extension/VaxHistoryModal';
import {
  useApplicationState,
  usePractice,
  useSession,
} from '@bluefox/contexts';
import { toast } from 'react-semantic-toasts';
import GS1 from '@bluefox/lib/gs1';
import { DateFormats } from '@bluefox/models/Dates';
import GeorgiaForm3231PdfDrawer from '@utils/Georgia/Form3231/pdfDrawer';
import ImmunizationForm from '@components/ImmunizationForm';
import ColoradoCertificateOfImmunizationForChildrenPdf from '@utils/Colorado/ColoradoCertificateOfImmunizationForChildren/pdfDrawer';
import ColoradoCertificateOfImmunizationForCollegePdf from '@utils/Colorado/CertificateOfImmunizationForCollege/pdfDrawer';
import { BILLER_ROLE } from '../../generalSettings';
import ErrorReportModal from '@components/ErrorReportModal';
import IllinoisStatePhysicalFormPdf from '@utils/Illinois/ILStatePhysicalForm/pdfDrawer';
import PatientMenuDropdown from '@components/PatientMenuDropdown';
import Form680Pdf from '@utils/Florida/Form680/pdfDrawer';
import { isVfcUninsured } from '@bluefox/lib/validations/isVfcUninsured';

type PatientScreenProps = {
  pageBlurred: boolean;
};

interface VaccinationsData {
  vaccinations: Vaccination[];
}

interface InconsistentVaccinationsData {
  vaccinations: Vaccination[];
}

interface PracticePatientProfileData {
  practicePatient: PracticePatient & {
    appointments: Appointment[];
    futureAppointments: Appointment[];
    insurances: Insurance[];
  };
}

const PatientScreen = ({ pageBlurred }: PatientScreenProps) => {
  const history = useHistory();
  const { isEmbedded } = useApplicationState();
  const practice = usePractice();
  const session = useSession();
  const { practicePatientId } = useParams<{ practicePatientId: string }>();
  const [todayAppointment, setTodayAppointment] = useState<Appointment>();
  const [practicePatient, setPracticePatient] = useState<PracticePatient>();
  const [patientData, setPatientData] = useState<PatientData>();

  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: '',
  });
  const [isVisibleDialog, setVisibleDialog] = useState(false);

  const [immunizationEntries, setImmunizationEntries] = useState(0);
  const [isFinished, setIsFinished] = useState(false);

  const [selectedInsuranceId, setSelectedInsuranceId] = useState<
    string | undefined
  >(undefined);

  const {
    data: patientVaccinationsData,
    refetch: refetchPatientVaccinationsData,
  } = useQuery<VaccinationsData>(VaccinationsByPracticePatientQuery, {
    variables: {
      practicePatientId,
    },
  });

  const vaccinationTypesReceived = patientVaccinationsData?.vaccinations.map(
    (v) => {
      return v.vaccine?.types;
    }
  );

  const vaccinationTypesReceivedArray = vaccinationTypesReceived
    ? Object.values(vaccinationTypesReceived).flat()
    : [];

  const handleGoToIntendedPage = useCallback(
    (location) => {
      if (!location) return;
      history.push(location);
    },
    [history]
  );

  useEffect(() => {
    if (isFinished) setImmunizationEntries(0);

    if (triggerExit.onOk) {
      setImmunizationEntries(0);
      handleGoToIntendedPage(triggerExit.path);
    }
    //@ts-ignore
    const unblock = history.block((location) => {
      if (
        immunizationEntries === 0 &&
        location.pathname !==
          `/${practice.handler}/patients/${practicePatientId}`
      ) {
        setTriggerExit((obj) => ({
          ...obj,
          onOk: true,
        }));
      } else if (
        immunizationEntries > 0 &&
        location.pathname !==
          `/${practice.handler}/patients/${practicePatientId}`
      ) {
        setTriggerExit((obj) => ({
          ...obj,
          onOk: false,
        }));
        setVisibleDialog(true);
      }

      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToIntendedPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    immunizationEntries,
    isFinished,
  ]);

  const consent = practicePatient?.consentForIis;
  const syncImmunization = () => {
    syncImmunizations({
      variables: {
        practicePatientId,
      },
    })
      .then((response) => {
        refetchPatientProfile();
        // MANAGE ERROR
        if (
          response.data?.SyncImmunizations?.error &&
          Object.keys(response.data?.SyncImmunizations?.error).length !== 0
        ) {
          const description = response.data.SyncImmunizations.error.msg;
          const type =
            response.data.SyncImmunizations.error.code === 500
              ? 'error'
              : 'warning';

          toast({
            title: '',
            description,
            type,
            time: 5000,
          });

          return;
        }

        if (response.data?.SyncImmunizations?.newData) {
          toast({
            title: 'Success',
            description: 'Successfully Synchronized',
            type: 'success',
            time: 5000,
          });
        }
      })
      .catch((err) => {
        toast({
          title: 'Error',
          description: err.message,
          type: 'error',
          time: 5000,
        });
      });
  };

  const {
    data,
    loading,
    refetch: refetchPatientProfile,
  } = useQuery<PracticePatientProfileData>(PracticePatientProfileQuery, {
    variables: {
      id: practicePatientId,
    },
  });

  const {
    data: InconsistentVaccinationsData,
    refetch: refetchInconsistentVaccinations,
  } = useQuery<InconsistentVaccinationsData>(
    InconsistentVaccinationsByPatientQuery,
    {
      variables: {
        practicePatientId,
      },
    }
  );

  const [retrieveAthenaPatientImmunizations, { data: athenaResponseData }] =
    useMutation(RetrieveAthenaPatientImmunizationsMutation, {
      variables: {
        practicePatientId,
      },
      refetchQueries: [
        {
          query: PracticePatientProfileQuery,
          variables: {
            id: practicePatientId,
          },
        },
      ],
    });

  const handleRetreiveAthenaImmunizations = () => {
    retrieveAthenaPatientImmunizations()
      .then((response) => {
        // MANAGE ERROR
        if (
          response.data?.retrieveAthenaPatientImmunizations?.error &&
          Object.keys(response.data?.retrieveAthenaPatientImmunizations?.error)
            .length !== 0
        ) {
          const description =
            response.data.retrieveAthenaPatientImmunizations.error.msg;
          const type =
            response.data.retrieveAthenaPatientImmunizations.error.code === 500
              ? 'error'
              : 'warning';

          toast({
            title: '',
            description,
            type,
            time: 5000,
          });

          return;
        }

        if (response.data?.retrieveAthenaPatientImmunizations?.newData) {
          toast({
            title: 'Success',
            description: 'Successfully Synchronized',
            type: 'success',
            time: 5000,
          });
        }
      })
      .catch((err) => {
        toast({
          title: 'Error',
          description: err.message,
          type: 'error',
          time: 5000,
        });
      });
  };

  const handleOnFinish = () => {
    //refetchPatientProfile();
    refetchInconsistentVaccinations();
  };

  const AthenaSyncBtn = () => {
    return (
      <PatientMenuButton
        label="Sync"
        icon="syringe"
        onClick={handleRetreiveAthenaImmunizations}
        minimalistic={isEmbedded}
        alert={alert}
      />
    );
  };

  const fullName = patientData?.preferredName
    ? `${patientData?.preferredName} (${patientData?.firstName} ${
        patientData?.middleName || ''
      } ${patientData?.lastName})`
    : `${patientData?.firstName} ${
        patientData?.middleName || ''
      } ${patientData?.lastName}`;

  const insurance: Insurance | undefined = data?.practicePatient.insurances
    ? data?.practicePatient.insurances[0]
    : undefined;

  const patientDob = data
    ? new Date(data.practicePatient.patientData.birthdate)
    : undefined;

  const patientAge = getAge(patientDob);
  const [syncImmunizations] = useMutation(SyncImmunizationsMutation);

  const settingsForms = data?.practicePatient.practice.settings?.forms;
  const hasOwnImmunizationRecordEnabled = useMemo(() => {
    return (
      settingsForms?.georgia?.form3231?.enabled ||
      settingsForms?.colorado?.certificateOfImmunization?.enabled ||
      settingsForms?.illinois?.physicalForm?.enabled ||
      settingsForms?.florida?.form680?.enabled
    );
  }, [settingsForms]);

  const renderImmunizationFormsButton = () => {
    if (settingsForms?.georgia?.form3231?.enabled) {
      return (
        <GeorgiaForm3231PdfDrawer
          patientData={patientData}
          vaccinations={patientVaccinationsData?.vaccinations}
          vaccinationTypesReceivedArray={vaccinationTypesReceivedArray}
          appointments={data?.practicePatient.futureAppointments ?? []}
          practice={practice}
          pageBlurred={pageBlurred}
        />
      );
    }

    if (settingsForms?.colorado?.certificateOfImmunization?.enabled) {
      return (
        <PatientMenuDropdown
          minimalistic={isEmbedded}
          label="Certificate of Immunization"
          items={[
            <ColoradoCertificateOfImmunizationForChildrenPdf
              patientData={patientData}
              vaccinations={patientVaccinationsData?.vaccinations}
              pageBlurred={pageBlurred}
            />,
            <ColoradoCertificateOfImmunizationForCollegePdf
              patientData={patientData}
              vaccinations={patientVaccinationsData?.vaccinations}
              pageBlurred={pageBlurred}
            />,
          ]}
        />
      );
    }

    if (settingsForms?.illinois?.physicalForm?.enabled) {
      return (
        <IllinoisStatePhysicalFormPdf
          patientData={patientData}
          vaccinations={patientVaccinationsData?.vaccinations}
          practice={practice}
          pageBlurred={pageBlurred}
        />
      );
    }

    if (settingsForms?.florida?.form680?.enabled) {
      return (
        <Form680Pdf
          patientData={patientData}
          vaccinations={patientVaccinationsData?.vaccinations}
          pageBlurred={pageBlurred}
        />
      );
    }

    return (
      <PatientMenuButton
        icon="print"
        label="View / export history"
        to="immunizations"
        alert={alert}
        minimalistic={isEmbedded}
      />
    );
  };

  const onFormSaveHandler = () => {
    setSelectedInsuranceId(undefined);
    refetchPatientProfile();
  };

  const onModalCloseHandler = () => {
    setSelectedInsuranceId(undefined);
  };

  useEffect(() => {
    if (!data || !data.practicePatient) return;

    setPracticePatient(data.practicePatient);

    setPatientData({
      ...data.practicePatient.patientData,
      practicePatientId: practicePatientId,
    });

    if (data.practicePatient.appointments.length) {
      setTodayAppointment(data.practicePatient.appointments[0]);
    }

    if (!data.practicePatient.appointments.length) {
      setTodayAppointment(undefined);
    }

    if (
      data.practicePatient.practice.settings?.athena?.enabled &&
      !data.practicePatient.practice.issName &&
      (moment().diff(data.practicePatient.immunizationSyncedAt, 'days') > 0 ||
        !data.practicePatient.immunizationSyncedAt)
    ) {
      handleRetreiveAthenaImmunizations();
    }

    if (
      !data.practicePatient.practice.issName ||
      moment().diff(data.practicePatient.immunizationSyncedAt, 'days') <= 0
    )
      return;

    syncImmunization();
  }, [data, data?.practicePatient, practicePatientId, syncImmunizations]);

  const alert =
    patientAge >= 19 && consent === null
      ? 'For Patients over 19 years old, consent is required to share their record with immunization registry.'
      : undefined;

  const handleVfcChange = (vfc: boolean) => {
    refetchPatientProfile();
  };

  const menu = (
    <Menu compact icon={!isEmbedded ? 'labeled' : undefined} size="large">
      {data?.practicePatient.practice.settings?.athena?.enabled && (
        <AthenaSyncBtn />
      )}
      <PatientMenuButton
        icon="user"
        label="Patient Info"
        to="info"
        alert={alert}
        minimalistic={isEmbedded}
      />
      <PatientMenuButton
        icon="dollar"
        label="Insurances"
        to="insurances"
        alert={
          !insurance && !practicePatient?.isSelfPay
            ? 'The insurance information is incomplete.'
            : ''
        }
        minimalistic={isEmbedded}
      />
      <PatientMenuButton
        icon="calendar alternate outline"
        label="Appointments"
        to="appointments"
        minimalistic={isEmbedded}
      />
      {hasOwnImmunizationRecordEnabled && (
        <ImmunizationForm
          trigger={
            <PatientMenuButton
              icon="plus circle"
              label="Immunization History"
              minimalistic={isEmbedded}
            />
          }
          onSave={() => {
            refetchPatientProfile();
            refetchPatientVaccinationsData();
          }}
        />
      )}
      {renderImmunizationFormsButton()}
      {isEmbedded && (
        <VaxHistoryModal
          practicePatientId={practicePatientId}
          trigger={
            <PatientMenuButton
              icon="table"
              label="Vax History"
              minimalistic={isEmbedded}
            />
          }
        />
      )}
    </Menu>
  );

  const BasicView = () => {
    return (
      <>
        <Modal
          size="small"
          open={isVisibleDialog}
          onClose={() => setVisibleDialog(false)}
          onOpen={() => setVisibleDialog(true)}
        >
          <Modal.Content>
            <Message
              warning
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div>
                <Icon size="huge" name="warning sign" />
              </div>
              <div>
                There are unsaved immunizations. Please click the green "Finish"
                button to save them.
                <div>
                  <b>
                    Are you sure you want to continue without saving
                    immunizations?
                  </b>
                </div>
              </div>
            </Message>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              content="Cancel"
              onClick={() => setVisibleDialog(false)}
            />
            <Button
              color="red"
              content="Yes, I want to cancel the immunization process"
              onClick={() => {
                setTriggerExit((obj) => ({
                  ...obj,
                  onOk: true,
                }));
                setVisibleDialog(false);
                setImmunizationEntries(0);
              }}
            />
          </Modal.Actions>
        </Modal>
        <Segment
          data-automation-id="patient-profile-head"
          padded
          basic
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            marginBottom: 0,
            paddingBottom: 0,
            paddingLeft: !isEmbedded ? 0 : undefined,
            paddingRight: !isEmbedded ? 0 : undefined,
            paddingTop: isEmbedded ? 0 : undefined,
          }}
        >
          <Header as="h1">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!patientData ? (
                <Segment basic style={{ width: '16rem', margin: '0' }}>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <div>
                        <Placeholder.Line />
                      </div>
                    </Placeholder.Header>
                  </Placeholder>
                </Segment>
              ) : (
                <div>{fullName}</div>
              )}

              {practicePatient && (
                <Label color={practicePatient?.active ? 'olive' : 'red'}>
                  {practicePatient?.active ? 'ACTIVE' : 'INACTIVE'}
                </Label>
              )}

              {practicePatient?.isSelfPay && (
                <Label color="grey">Self pay</Label>
              )}

              {/* <Button size="tiny"  onClick={syncImmunization} /> */}
            </div>
            <Header.Subheader>
              {!data ? (
                <Segment basic style={{ width: '22rem', margin: '0' }}>
                  <Placeholder fluid>
                    <Placeholder.Header>
                      <div>
                        <Placeholder.Line />
                      </div>
                    </Placeholder.Header>
                  </Placeholder>
                </Segment>
              ) : (
                <>
                  <div>
                    {moment(patientData?.birthdate).format(DateFormats.DATE)}
                    {` / `}
                    <Moment
                      durationFromNow
                      date={patientData?.birthdate}
                      trim
                      format="y [years], M [months], d [days]"
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {insurance && isVfcUninsured(insurance)
                      ? 'Uninsured'
                      : insurance?.company?.name}
                    {insurance && (
                      <Label
                        color={insurance?.vfcEligible ? 'orange' : 'teal'}
                        content={insurance?.vfcEligible ? 'VFC' : 'Private'}
                      />
                    )}
                    {insurance?.primary && (
                      <Popup
                        trigger={
                          <Icon
                            name="edit"
                            color="black"
                            style={{ marginLeft: '0.5rem' }}
                            onClick={() =>
                              setSelectedInsuranceId(insurance?.id)
                            }
                          />
                        }
                        content="Edit insurance"
                        size="small"
                      />
                    )}
                  </div>
                </>
              )}
              <InsurancesForm
                onSave={onFormSaveHandler}
                onModalClose={onModalCloseHandler}
                propPracticePatientId={practicePatientId}
                propInsuranceId={selectedInsuranceId}
              />
            </Header.Subheader>
          </Header>
          {todayAppointment && (
            <AppointmentBar appointment={todayAppointment} />
          )}
          {menu}
        </Segment>
        <Grid as={Segment}>
          <Grid.Row>
            <Scanner
              onFinish={handleOnFinish}
              getTotalEntries={setImmunizationEntries}
              setIsFinished={(finished) => setIsFinished(finished)}
              pageBlurred={pageBlurred}
            />
          </Grid.Row>
        </Grid>
        {!!InconsistentVaccinationsData &&
        !!InconsistentVaccinationsData.vaccinations.length
          ? InconsistentVaccinationsData.vaccinations.map((v) => {
              const gs1 = new GS1(v.barcode || '');
              if (!v.vaccine) {
                return (
                  <Message warning key={`warning-${v.id}-missing-ndc`}>
                    <Icon name="warning sign" />
                    {` Missing NDC: The vaccine NDC ${gs1.ndc} - Lot ${
                      gs1.lot
                    } (scanned on ${moment(v.givenAt).format(
                      'MM-DD-YYYY'
                    )}) was not found in our registry. It may take up to 24 hours to appear in the Vax History, Vax Dash, and sync with the EMR.`}
                  </Message>
                );
              }

              if (v.vaccine && !v.inventory) {
                return (
                  <Message warning key={`warning-${v.id}-missing-lot`}>
                    <Icon name="warning sign" />
                    {`Missing lot: The vaccine ${v.vaccine
                      ?.name} (scanned on ${moment(v.givenAt).format(
                      'MM-DD-YYYY'
                    )}) is under review due to a mismatch with our inventory lot number. It may take up to 24 hours to sync with the EMR.`}
                  </Message>
                );
              }
              return [];
            })
          : null}
        <Route
          path={`/${practice.handler}/patients/:practicePatientId/insurances/_new`}
        >
          <InsurancesForm
            onSave={onFormSaveHandler}
            onVfcChange={handleVfcChange}
          />
        </Route>
        <Route
          path={`/${practice.handler}/patients/:practicePatientId/insurances/:insuranceId`}
        >
          <InsurancesForm
            onSave={onFormSaveHandler}
            onVfcChange={handleVfcChange}
          />
        </Route>
      </>
    );
  };

  return (
    <PracticePatientProvider practicePatient={practicePatient}>
      <PatientDataProvider patientData={patientData}>
        {isEmbedded ? (
          <>
            {BasicView()}
            <>
              {session?.account?.role !== BILLER_ROLE && (
                <ErrorReportModal>
                  <div className="fixed-support-bubble">
                    <Icon name="chat" className="bubble-support-icon" />
                  </div>
                </ErrorReportModal>
              )}
            </>
          </>
        ) : (
          <>
            <FullLayout loading={loading}>
              {BasicView()}

              {!!patientData && (
                <VaccineCalendarWidget
                  practicePatientId={practicePatientId}
                  data-automation-id={`patient-vax-table`}
                />
              )}
            </FullLayout>
          </>
        )}
      </PatientDataProvider>
    </PracticePatientProvider>
  );
};

export default PatientScreen;

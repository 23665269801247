import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  SolvePendingBorrows,
  GetClaimsVFCInconsistencies,
  GetInconsistenciesToSwap,
  UpdateRedundantSwappedDate,
} from '@bluefox/graphql/billing';
import { GetSimpleInventoryForBorrowing } from '@bluefox/graphql/inventory';
import { InventoryStatus } from '@bluefox/models/Inventory';
import {
  Button,
  Container,
  Dropdown,
  Header,
  Input,
  Label,
  Message,
  Table,
} from 'semantic-ui-react';
import { Inventory } from '@bluefox/models/models';
import { toast } from 'react-semantic-toasts';
import { useApplicationState } from '@bluefox/contexts';
import { debounce } from '@bluefox/lib/debounce';
import { BorrowingInventoryAdjustments } from '@graphql/inventory';
import BorrowingCurrentCycleClaimListModal from './BorrowingCurrentCycleClaimListModal';
import { VfcInconsistencyInsurance } from '@bluefox/models/VFCInconsistency';

export interface Inconsistency {
  createdAt: string;
  id: string;
  practiceId: string;
  practice: {
    name: string;
  };
  claimId: string;
  inventoryId: string;
  vaccinationId: string;
  inventory: {
    doses: number;
    expiration: Date;
    id: string;
    vfc: boolean;
    lot: string;
    vaccine: {
      id: string;
      name: string;
      aka: string;
      types: string[];
      saleNdc: string;
    };
  };
  updatedAt: string;
  type: string;
  status: string;
  claimUpdatesId: string;
  claim: {
    id: string;
    givenAt: Date;
    insurance: VfcInconsistencyInsurance[];
    practicePatient: {
      patientData: {
        birthdate: Date;
        firstName: string;
        lastName: string;
      };
    };
  };
}

interface Inconsistencies {
  vfcInconsistencies: Inconsistency[];
  aggregating: {
    aggregate: {
      count: number;
    };
  };
}

interface InconsistencyWithClaimIdsAndVaccineToReturn extends Inconsistency {
  vfcInconsistentClaims: Inconsistency[];
  vaccineToReturn: AlternativeToReturn[];
  rejected: number;
}

interface Result {
  text?: string | undefined;
  content?: ReactNode;
  value: string | undefined;
}

interface AlternativeToReturn {
  index: number;
  inventoryId: string;
  quantity: number;
}

interface Change {
  change: number;
  type: string;
}

const MagicWordsDict = {
  Status: {
    Applied: 'applied',
    Solved: 'solved',
    Pending: 'pending',
  },
  Type: {
    Returned: 'returned',
    Recovered: 'recovered',
    Borrow: 'borrow',
  },
  Reason: {
    Borrowing: 'borrowing',
  },
  StatusLog: {
    From: 'Fix Pending Borrowing Tool',
  },
  UpdateColumns: {
    Doses: 'doses',
    SourceAdjustmentDetail: 'sourceAdjustmentDetailId',
    TargetAdjustmentDetail: 'targetAdjustmentDetailId',
    Status: 'status',
  },
  Constraint: {
    InventoriesPrimaryKey: 'inventories_pkey',
    ClaimVfcInconsistenciesIdKey: 'claim_vfc_inconsistencies_id_key',
  },
};

interface CurrentCycleProps {
  practiceId: string;
  arrayOfRedundantIds: string[];
}

const CurrentCycle = ({
  practiceId,
  arrayOfRedundantIds,
}: CurrentCycleProps) => {
  const { session } = useApplicationState();
  const [inconsistencies, setInconsistencies] = useState<
    InconsistencyWithClaimIdsAndVaccineToReturn[]
  >([]);
  const [inventory, setInventory] = useState<Inventory[]>([]);

  const debouncedRef = useRef<ReturnType<typeof debounce>>();

  // This function groups all the same vaccine inconsistencies into only one object row
  const transformData = (
    data: Inconsistency[]
  ): InconsistencyWithClaimIdsAndVaccineToReturn[] => {
    const inconsistenciesByKey = data.reduce(
      (acc, item) => {
        const key = `${item?.inventory?.vaccine?.id}-${
          item?.inventory?.vfc ? 'vfc' : 'private'
        }`;
        const inconsistentClaims = acc[key]
          ? [...acc[key].vfcInconsistentClaims, item]
          : [item];
        const inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn = {
          ...item,
          vaccineToReturn: [
            {
              index: 0,
              inventoryId: '',
              quantity: 0,
            },
          ],
          rejected: 0,
          vfcInconsistentClaims: inconsistentClaims,
        };

        return {
          ...acc,
          [key]: inconsistency,
        };
      },
      {} as { [key: string]: InconsistencyWithClaimIdsAndVaccineToReturn }
    );

    const result = Object.values(inconsistenciesByKey);
    result.sort((i1, i2) => {
      const name1 = `${i1.inventory.vfc ? 'A' : 'B'}${
        i1.inventory.vaccine.name
      }`;
      const name2 = `${i2.inventory.vfc ? 'A' : 'B'}${
        i2.inventory.vaccine.name
      }`;
      return name1.localeCompare(name2);
    });
    return result;
  };

  const [getInventories] = useLazyQuery(GetSimpleInventoryForBorrowing, {
    onCompleted(data) {
      setInventory(
        data.inventories.map(
          ({ __typename, ...i }: { __typename: string }) => i // Typename is left out
        )
      );
    },
  });

  const { refetch: refetchGetInconsistenciesToSwap } =
    useQuery<Inconsistencies>(GetInconsistenciesToSwap, {
      variables: {
        criteria: {
          practiceId: { _eq: practiceId },
          status: { _eq: MagicWordsDict.Status.Pending },
          type: { _eq: MagicWordsDict.Type.Borrow },
          readyToBeSwapped: { _eq: true },
        },
      },
      onCompleted(data) {
        setInconsistencies(transformData(data.vfcInconsistencies));
        getInventories({
          variables: {
            criteria: {
              practiceId: { _eq: practiceId },
              status: {
                _eq: InventoryStatus.received,
              },
            },
          },
        });
      },
    });

  const [
    updateBorrowPendingVfcInconsistencies,
    { loading: updatePendingBorrowsLoading },
  ] = useMutation(SolvePendingBorrows);

  const [updateRedundantSwappedDate] = useMutation(UpdateRedundantSwappedDate);

  const loading = useMemo(
    () => updatePendingBorrowsLoading,
    [updatePendingBorrowsLoading]
  );

  function arraysContainSameItems(
    array1: string[] = [],
    array2: string[] = []
  ) {
    return (
      array1.length === array2.length &&
      array1.every((a) => array2.includes(a)) &&
      array2.every((a) => array1.includes(a))
    );
  }

  // This function creates the possible alternatives vaccines to return
  function buildOptions(
    inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn,
    index: number
  ): Result[] {
    const results: Result[] = [];

    // Check for matches using lot
    const lotMatch = inventory.filter((inv) => {
      return (
        inv.lot === inconsistency.inventory.lot &&
        inv.vfc !== inconsistency.inventory.vfc &&
        arraysContainSameItems(
          inv.vaccine?.types,
          inconsistency.inventory.vaccine.types
        ) &&
        inv.doses > 0
      );
    });

    lotMatch.map((result) => {
      const alreadyIn = results.find((r) => r.value === result.id);
      if (!alreadyIn) {
        return results.push({
          text: `${result.vaccine?.name} - Lot: ${result.lot} -${' '}
          ${result.vfc ? 'VFC' : 'Private'} - ${result.doses} doses - by lot`,
          content: (
            <Header as="h4">
              <Header.Content>
                {result.vaccine?.name} - Lot: {result.lot} -{' '}
                <Label color={result.vfc ? 'orange' : 'teal'} size="mini">
                  {result.vfc ? 'VFC' : 'Private'}
                </Label>{' '}
                - {result.doses} doses
              </Header.Content>
              <Header.Subheader>
                {result.vaccine?.types?.map((t) => (
                  <Label key={t} color="black" size="mini">
                    {t}
                  </Label>
                ))}{' '}
                - by lot
              </Header.Subheader>
            </Header>
          ),
          value: result.id,
        });
      } else {
        return null;
      }
    });

    // Check for matches using name
    const nameMatch = inventory.filter(
      (inv) =>
        inv.vaccine?.name === inconsistency.inventory.vaccine.name &&
        inv.vfc !== inconsistency.inventory.vfc &&
        arraysContainSameItems(
          inv.vaccine?.types,
          inconsistency.inventory.vaccine.types
        ) &&
        inv.doses > 0
    );
    nameMatch.map((result) => {
      const alreadyIn = results.find((r) => r.value === result.id);
      if (!alreadyIn) {
        return results.push({
          text: `${result.vaccine?.name} - Lot: ${result.lot} -${' '}
          ${result.vfc ? 'VFC' : 'Private'} - ${result.doses} doses - by name`,
          content: (
            <Header as="h4">
              <Header.Content>
                {result.vaccine?.name} - Lot: {result.lot} -{' '}
                <Label color={result.vfc ? 'orange' : 'teal'} size="mini">
                  {result.vfc ? 'VFC' : 'Private'}
                </Label>{' '}
                - {result.doses} doses
              </Header.Content>
              <Header.Subheader>
                {result.vaccine?.types?.map((t) => (
                  <Label key={t} color="black" size="mini">
                    {t}
                  </Label>
                ))}{' '}
                - by name
              </Header.Subheader>
            </Header>
          ),
          value: result.id,
        });
      } else {
        return null;
      }
    });

    // Check for matches using types
    const typeMatch = inventory.filter(
      (inv) =>
        inv.vfc !== inconsistency.inventory.vfc &&
        arraysContainSameItems(
          inv.vaccine?.types,
          inconsistency.inventory.vaccine.types
        ) &&
        inv.doses > 0
    );
    typeMatch.map((result) => {
      const alreadyIn = results.find((r) => r.value === result.id);
      if (!alreadyIn) {
        return results.push({
          text: `${result.vaccine?.name} - Lot: ${result.lot} -${' '}
          ${result.vfc ? 'VFC' : 'Private'} - ${result.doses} doses - by type`,
          content: (
            <Header as="h4">
              <Header.Content>
                {result.vaccine?.name} - Lot: {result.lot} -{' '}
                <Label color={result.vfc ? 'orange' : 'teal'} size="mini">
                  {result.vfc ? 'VFC' : 'Private'}
                </Label>{' '}
                - {result.doses} doses
              </Header.Content>
              <Header.Subheader>
                {result.vaccine?.types?.map((t) => (
                  <Label key={t} color="black" size="mini">
                    {t}
                  </Label>
                ))}{' '}
                - by type
              </Header.Subheader>
            </Header>
          ),
          value: result.id,
        });
      } else {
        return null;
      }
    });

    const selectedResults = inconsistency.vaccineToReturn.map((selected) => {
      if (selected.index !== index) {
        return selected.inventoryId;
      } else {
        return null;
      }
    });

    const filteredResults = results.filter((result) => {
      if (result.value) {
        return !selectedResults.includes(result.value);
      } else {
        return null;
      }
    });

    return filteredResults;
  }

  function updateInventoryToReturn(
    array: AlternativeToReturn[],
    index: number,
    newInventoryId: string
  ) {
    return array.map((item) => {
      if (item.index === index) {
        return { ...item, inventoryId: newInventoryId };
      } else {
        return item;
      }
    });
  }

  const handleAlternative = (
    inventoryId: string,
    index: number,
    alternativeInventoryId: string
  ) => {
    const updatedInconsistencies = inconsistencies.map((inconsistency) => {
      if (inconsistency.inventoryId === inventoryId) {
        const foundAlternative = inconsistency.vaccineToReturn.find(
          (alt) => alt.index === index
        );
        if (foundAlternative) {
          return {
            ...inconsistency,
            vaccineToReturn: updateInventoryToReturn(
              inconsistency.vaccineToReturn,
              index,
              alternativeInventoryId
            ),
          };
        } else {
          return {
            ...inconsistency,
            vaccineToReturn: [
              ...inconsistency.vaccineToReturn,
              {
                index,
                inventoryId: alternativeInventoryId,
                quantity: 0,
              },
            ],
          };
        }
      } else {
        return inconsistency;
      }
    });

    setInconsistencies(
      updatedInconsistencies.map((updatedInconsistency) => {
        if (
          !!updatedInconsistency.vaccineToReturn.length &&
          updatedInconsistency.vaccineToReturn[
            updatedInconsistency.vaccineToReturn.length - 1
          ].inventoryId === ''
        ) {
          return updatedInconsistency;
        } else {
          return {
            ...updatedInconsistency,
            vaccineToReturn: [
              ...updatedInconsistency.vaccineToReturn,
              {
                index: updatedInconsistency.vaccineToReturn.length,
                inventoryId: '',
                quantity: 0,
              },
            ],
          };
        }
      })
    );
  };

  function getTotalQuantity(data: AlternativeToReturn[]) {
    return data.reduce((total, item) => total + (item.quantity || 0), 0);
  }

  const handleAlternativeQuantity = (
    inventoryId: string,
    index: number,
    alternativeQuantity: number
  ) => {
    setInconsistencies((prevInconsistencies) =>
      prevInconsistencies.map((inconsistency) => {
        if (inconsistency.inventoryId === inventoryId) {
          const totalConfirmedQuantity = getTotalQuantity(
            inconsistency.vaccineToReturn
          );
          const maxQuantityPossible =
            inconsistency.vfcInconsistentClaims.length -
            inconsistency.rejected -
            totalConfirmedQuantity +
            (inconsistency.vaccineToReturn[index]?.quantity || 0);

          const alternativeInventoryId =
            inconsistency.vaccineToReturn[index]?.inventoryId;
          const alternativeInventory = inventory.find(
            (inv) => inv.id === alternativeInventoryId
          );
          const availableAlternativeDoses = alternativeInventory
            ? alternativeInventory.doses
            : 0;

          const updatedVaccineToReturn = inconsistency.vaccineToReturn.map(
            (item, idx) => {
              if (idx === index) {
                return {
                  ...item,
                  quantity: Math.min(
                    Math.max(alternativeQuantity, 0),
                    maxQuantityPossible,
                    availableAlternativeDoses
                  ),
                };
              }
              return item;
            }
          );

          return {
            ...inconsistency,
            vaccineToReturn: updatedVaccineToReturn,
          };
        }
        return inconsistency;
      })
    );
  };

  async function prepareQueryObjects(
    inconsistencies: InconsistencyWithClaimIdsAndVaccineToReturn[]
  ) {
    const inventoryAdjustmentDetailsData = inconsistencies.map((i) => {
      let initialNumber = 0; // This numbers tracks what claims to update source/target
      const returnedInventoryAdjustmentDetailsData = i.vaccineToReturn
        .filter((i) => !!i.inventoryId && i.quantity > 0)
        .map((vtr) => {
          // vtr = vaccineToReturn
          const foundInventory = inventory.find(
            (inv) => inv.id === vtr.inventoryId
          )!;
          const obj = {
            currentDoses: foundInventory.doses,
            newDoses: foundInventory.doses - vtr.quantity,
            type: MagicWordsDict.Type.Returned,
            reason: MagicWordsDict.Reason.Borrowing,
            status: MagicWordsDict.Status.Applied,
            comment: '',
            statusLog: [
              {
                account: session?.account,
                status: MagicWordsDict.Status.Applied,
                from: MagicWordsDict.StatusLog.From,
                updatedAt: new Date(),
              },
            ],
            inventory: {
              data: {
                id: vtr.inventoryId,
                practiceId: foundInventory.practiceId,
                vaccineId: foundInventory.vaccineId,
                lot: foundInventory.lot,
                expiration: foundInventory.expiration,
                doses: foundInventory.doses - vtr.quantity,
                vfc: foundInventory.vfc,
              },
              on_conflict: {
                constraint: MagicWordsDict.Constraint.InventoriesPrimaryKey,
                update_columns: [MagicWordsDict.UpdateColumns.Doses],
              },
            },
            sourceClaimVfcInconsistencies: {
              data: i.vfcInconsistentClaims
                .slice(initialNumber, initialNumber + vtr.quantity)
                .map((inconsistency) => ({
                  id: inconsistency.id,
                  claimId: inconsistency.claimId,
                  status: MagicWordsDict.Status.Solved,
                  inventoryId: inconsistency.inventoryId,
                  vaccinationId: inconsistency.vaccinationId,
                  practiceId: inconsistency.practiceId,
                  claimUpdatesId: inconsistency.claimUpdatesId,
                })),
              on_conflict: {
                constraint:
                  MagicWordsDict.Constraint.ClaimVfcInconsistenciesIdKey,
                update_columns: [
                  MagicWordsDict.UpdateColumns.SourceAdjustmentDetail,
                  MagicWordsDict.UpdateColumns.Status,
                ],
              },
            },
            targetClaimVfcInconsistencies: {
              data: [],
              on_conflict: {
                constraint:
                  MagicWordsDict.Constraint.ClaimVfcInconsistenciesIdKey,
                update_columns: [
                  MagicWordsDict.UpdateColumns.TargetAdjustmentDetail,
                  MagicWordsDict.UpdateColumns.Status,
                ],
              },
            },
          };
          initialNumber += vtr.quantity;
          return obj;
        });

      initialNumber = 0; // This numbers tracks what claims to update source/target
      const recoveredInventoryAdjustmentDetailsData = i.vaccineToReturn
        .filter((i) => !!i.inventoryId && i.quantity > 0)
        .map((vtr) => {
          const {
            id: originalInventoryId,
            vaccine: _,
            ...originalInventory
          } = inventory.find((inv) => inv.id === vtr.inventoryId)!;
          const {
            id: foundInventoryId,
            vaccine: __,
            ...foundInventory
          } = inventory.find(
            (inv) =>
              inv.vaccineId === originalInventory.vaccineId &&
              inv.lot === originalInventory.lot &&
              inv.status === originalInventory.status &&
              inv.expiration === originalInventory.expiration &&
              inv.vfc !== originalInventory.vfc
          ) || {
            ...originalInventory,
            doses: 0,
            vfc: !originalInventory.vfc,
          };
          const obj = {
            currentDoses: foundInventory.doses,
            newDoses: foundInventory.doses + vtr.quantity,
            type: MagicWordsDict.Type.Recovered,
            reason: MagicWordsDict.Reason.Borrowing,
            status: MagicWordsDict.Status.Applied,
            comment: '',
            statusLog: [
              {
                account: session?.account,
                status: MagicWordsDict.Status.Applied,
                from: MagicWordsDict.StatusLog.From,
                updatedAt: new Date(),
              },
            ],
            inventory: {
              data: {
                ...foundInventory,
                ...(foundInventoryId ? { id: foundInventoryId } : {}),
                doses: foundInventory.doses + vtr.quantity,
              },
              on_conflict: {
                constraint: MagicWordsDict.Constraint.InventoriesPrimaryKey,
                update_columns: [MagicWordsDict.UpdateColumns.Doses],
              },
            },
            sourceClaimVfcInconsistencies: {
              data: [],
              on_conflict: {
                constraint:
                  MagicWordsDict.Constraint.ClaimVfcInconsistenciesIdKey,
                update_columns: [
                  MagicWordsDict.UpdateColumns.SourceAdjustmentDetail,
                  MagicWordsDict.UpdateColumns.Status,
                ],
              },
            },
            targetClaimVfcInconsistencies: {
              data: i.vfcInconsistentClaims
                .slice(initialNumber, initialNumber + vtr.quantity)
                .map((inconsistency) => ({
                  id: inconsistency.id,
                  claimId: inconsistency.claimId,
                  status: MagicWordsDict.Status.Solved,
                  inventoryId: inconsistency.inventoryId,
                  vaccinationId: inconsistency.vaccinationId,
                  practiceId: inconsistency.practiceId,
                  claimUpdatesId: inconsistency.claimUpdatesId,
                })),
              on_conflict: {
                constraint:
                  MagicWordsDict.Constraint.ClaimVfcInconsistenciesIdKey,
                update_columns: [
                  MagicWordsDict.UpdateColumns.TargetAdjustmentDetail,
                  MagicWordsDict.UpdateColumns.Status,
                ],
              },
            },
          };
          initialNumber += vtr.quantity;
          return obj;
        });

      const dataToReturn = [
        ...returnedInventoryAdjustmentDetailsData,
        ...recoveredInventoryAdjustmentDetailsData,
      ];
      if (dataToReturn.length > 0) return dataToReturn.flat();
      return null;
    });

    const allInventoryAdjustmentDetails = inventoryAdjustmentDetailsData
      .filter((detail) => detail !== null)
      .flat();

    function mergeInventoryEntries(entries: any[]) {
      const mergedMap = new Map();

      for (const entry of entries) {
        const key = `${entry.inventory.data.id}-${entry.inventory.data.lot}-${entry.inventory.data.vfc}`;
        if (!mergedMap.has(key)) {
          mergedMap.set(key, {
            ...entry,
            doseChanges: [
              {
                type: entry.type,
                change: entry.newDoses - entry.currentDoses,
              },
            ],
          });
        } else {
          const existingEntry = mergedMap.get(key);

          // Merge claim inconsistencies
          existingEntry.sourceClaimVfcInconsistencies.data = [
            ...existingEntry.sourceClaimVfcInconsistencies.data,
            ...entry.sourceClaimVfcInconsistencies.data,
          ];
          existingEntry.targetClaimVfcInconsistencies.data = [
            ...existingEntry.targetClaimVfcInconsistencies.data,
            ...entry.targetClaimVfcInconsistencies.data,
          ];

          // Collect all dose changes
          existingEntry.doseChanges.push({
            type: entry.type,
            change: entry.newDoses - entry.currentDoses,
          });

          // Update status logs
          existingEntry.statusLog = [
            ...existingEntry.statusLog,
            ...entry.statusLog,
          ];
        }
      }

      // Process the merged entries
      return Array.from(mergedMap.values()).map((entry) => {
        const netDoseChange = entry.doseChanges.reduce(
          (sum: number, change: Change) => {
            return sum + change.change;
          },
          0
        );
        const finalDoses = entry.currentDoses + netDoseChange;

        return {
          ...entry,
          type: netDoseChange >= 0 ? 'recovered' : 'returned',
          currentDoses: entry.currentDoses,
          newDoses: finalDoses,
          inventory: {
            ...entry.inventory,
            data: {
              ...entry.inventory.data,
              doses: finalDoses,
            },
          },
        };
      });
    }

    const mergedInventoryAdjustmentDetails = mergeInventoryEntries(
      allInventoryAdjustmentDetails
    );

    const vfcInventoryAdjustmentDetails =
      mergedInventoryAdjustmentDetails.filter(
        (detail) => detail.inventory.data.vfc
      );
    const privateInventoryAdjustmentDetails =
      mergedInventoryAdjustmentDetails.filter(
        (detail) => !detail.inventory.data.vfc
      );

    const inventoryAdjustments = [
      {
        practiceId: practiceId,
        status: MagicWordsDict.Status.Applied,
        statusLog: [
          {
            account: session?.account,
            status: MagicWordsDict.Status.Applied,
            from: MagicWordsDict.StatusLog.From,
            updatedAt: new Date(),
          },
        ],
        vfc: true,
        inventoryAdjustmentDetails: {
          data: vfcInventoryAdjustmentDetails,
        },
      },
      {
        practiceId: practiceId,
        status: MagicWordsDict.Status.Applied,
        statusLog: [
          {
            account: session?.account,
            status: MagicWordsDict.Status.Applied,
            from: MagicWordsDict.StatusLog.From,
            updatedAt: new Date(),
          },
        ],
        vfc: false,
        inventoryAdjustmentDetails: {
          data: privateInventoryAdjustmentDetails,
        },
      },
    ];

    const mutationObjects = inventoryAdjustments.filter((adjustment) => {
      adjustment.inventoryAdjustmentDetails.data.map((caso) => {
        return delete caso.doseChanges;
      });
      return adjustment.inventoryAdjustmentDetails.data.length > 0;
    });

    if (mutationObjects.length > 0) {
      try {
        await updateBorrowPendingVfcInconsistencies({
          variables: {
            objects: mutationObjects,
          },
          refetchQueries: [
            GetClaimsVFCInconsistencies(true),
            GetInconsistenciesToSwap,
            BorrowingInventoryAdjustments,
          ],
        });
        await updateRedundantSwappedDate({
          variables: {
            redundantIds: arrayOfRedundantIds,
            swappedDate: new Date(),
          },
        });
        refetchGetInconsistenciesToSwap();
        toast({
          title: 'Borrows Solved Successfully',
          type: 'success',
          time: 1000,
        });
      } catch (e) {
        toast({
          title: `Failed Solving Borrow Pending Inconsistencies. ${e}`,
          type: 'error',
          time: 5000,
        });
      }
    } else {
      toast({
        title: `Nothing to solve`,
        type: 'error',
        time: 1000,
      });
    }
  }

  useEffect(
    () => () => {
      debouncedRef.current?.cancel();
    },
    []
  );

  return (
    <Container>
      <Container
        style={{
          display: 'flex',
          gap: '10px',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          primary
          onClick={() => {
            debouncedRef.current?.cancel();
            debouncedRef.current = debounce(() => {
              prepareQueryObjects(inconsistencies);
            }, 500);

            debouncedRef.current();
          }}
          disabled={loading}
        >
          Confirm Swap
        </Button>
      </Container>

      {inconsistencies.length > 0 ? (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Inventory Used</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Borrowed Quantity</Table.HeaderCell>
              <Table.HeaderCell>Inventory to recover from</Table.HeaderCell>
              <Table.HeaderCell>Confirmed Quantity to Return</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {inconsistencies.map(
              (inconsistency: InconsistencyWithClaimIdsAndVaccineToReturn) => {
                return (
                  <Table.Row key={inconsistency.inventoryId}>
                    <Table.Cell>
                      <Header>
                        {inconsistency.inventory?.vaccine?.name}
                        <Label
                          content={
                            inconsistency.inventory.vfc ? 'VFC' : 'Private'
                          }
                          size="tiny"
                          color={
                            inconsistency.inventory.vfc ? 'orange' : 'teal'
                          }
                        />
                        <Header.Subheader>
                          {inconsistency.inventory.vaccine.saleNdc} |{' '}
                          {inconsistency.inventory.vaccine?.types?.map((t) => (
                            <Label key={t} color="black" size="mini">
                              {t}
                            </Label>
                          ))}
                        </Header.Subheader>
                      </Header>
                    </Table.Cell>

                    <Table.Cell>
                      <BorrowingCurrentCycleClaimListModal
                        inconsistenciesList={
                          inconsistency.vfcInconsistentClaims
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {inconsistency.vfcInconsistentClaims.length}{' '}
                    </Table.Cell>

                    <Table.Cell>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        {inconsistency.vaccineToReturn.map(
                          (vToReturn, indx) => {
                            const options = buildOptions(inconsistency, indx);
                            if (!options.length) {
                              if (indx === 0) {
                                return (
                                  <p key={`paragraph${indx}`}>
                                    No alternatives found.
                                  </p>
                                );
                              } else {
                                return;
                              }
                            } else {
                              return (
                                <Dropdown
                                  style={{ height: '60px' }}
                                  key={indx}
                                  selection
                                  search
                                  fluid
                                  value={vToReturn.inventoryId}
                                  onChange={(_, { value }) => {
                                    handleAlternative(
                                      inconsistency.inventoryId,
                                      indx,
                                      value as string
                                    );
                                  }}
                                  options={options}
                                  placeholder="Choose an option"
                                />
                              );
                            }
                          }
                        )}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        {inconsistency.vaccineToReturn.map(
                          (vToReturn, indx) => {
                            if (vToReturn.inventoryId) {
                              return (
                                <Input
                                  style={{ height: '60px' }}
                                  key={indx}
                                  placeholder="0"
                                  value={vToReturn.quantity || 0}
                                  onChange={(_, { value }) => {
                                    if (Number(value) >= 0) {
                                      handleAlternativeQuantity(
                                        inconsistency.inventoryId,
                                        indx,
                                        Math.min(
                                          Number(value),
                                          inconsistency.vfcInconsistentClaims
                                            .length -
                                            getTotalQuantity(
                                              inconsistency.vaccineToReturn
                                            ) -
                                            inconsistency.rejected +
                                            Number(value)
                                        ) <= 0
                                          ? 0
                                          : Math.min(
                                              Number(value),
                                              inconsistency
                                                .vfcInconsistentClaims.length -
                                                getTotalQuantity(
                                                  inconsistency.vaccineToReturn
                                                ) -
                                                inconsistency.rejected +
                                                Number(value)
                                            )
                                      );
                                    }
                                  }}
                                  disabled={!vToReturn.inventoryId}
                                />
                              );
                            } else if (
                              buildOptions(inconsistency, indx).length
                            ) {
                              return (
                                <div
                                  key={`div${indx}`}
                                  style={{ height: '60px' }}
                                ></div>
                              );
                            }
                          }
                        )}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              }
            )}
          </Table.Body>
        </Table>
      ) : (
        <Message>No Pending Borrows found.</Message>
      )}
    </Container>
  );
};

export default CurrentCycle;

import {
  formatDateToMMDDYYYYV2,
  formatDatetimeToMMDDYYY,
} from '@bluefox/lib/formatters';
import React from 'react';
import {
  ModalContent,
  ModalActions,
  Button,
  Header,
  Modal,
  Table,
  Label,
  Popup,
} from 'semantic-ui-react';
import { Inconsistency } from './CurrentCycle';

type BorrowingCurrentCycleClaimListModalProps = {
  inconsistenciesList: Inconsistency[];
};

function BorrowingCurrentCycleClaimListModal({
  inconsistenciesList,
}: BorrowingCurrentCycleClaimListModalProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      closeIcon
      open={open}
      trigger={
        <Popup
          trigger={
            <Button
              primary
              className="min-component-vertical-margin"
              icon="eye"
              size="tiny"
              onClick={() => setOpen(true)}
            />
          }
          content="See claims involved"
        />
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header icon="file outline" content="Claims list" />
      <ModalContent>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Patient
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Insurance
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="2" width={2} textAlign="center">
                Service Date
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Inventory Used <hr /> NDC
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan="1" width={1} textAlign="center">
                Vaccine <hr /> Lot
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {inconsistenciesList.map((inconsistency) => (
              <Table.Row key={inconsistency.id}>
                <Table.Cell textAlign="center">
                  {`${inconsistency.claim?.practicePatient.patientData
                    .firstName} ${inconsistency.claim?.practicePatient
                    .patientData.lastName} (${
                    inconsistency.claim?.practicePatient.patientData.birthdate
                      ? formatDatetimeToMMDDYYY(
                          inconsistency.claim?.practicePatient.patientData
                            .birthdate
                        )
                      : '-'
                  })`}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {inconsistency.claim?.insurance[0].insuranceCompanyName}{' '}
                  <Label
                    content={
                      inconsistency.claim?.insurance[0].vfcEligible
                        ? 'VFC Eligible'
                        : 'Private'
                    }
                    size="tiny"
                    color={
                      inconsistency.claim?.insurance[0].vfcEligible
                        ? 'orange'
                        : 'teal'
                    }
                  />
                  <Label
                    basic
                    size="tiny"
                    content={`Member ID: ${
                      inconsistency.claim?.insurance[0].memberId || '-'
                    }`}
                    style={{ marginTop: '0.2rem' }}
                  />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {inconsistency.claim?.givenAt
                    ? formatDateToMMDDYYYYV2(
                        inconsistency.claim?.givenAt.toString()
                      )
                    : '-'}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {inconsistency.inventory?.vfc ? (
                    <>
                      <Label
                        content="VFC Eligible"
                        size="tiny"
                        color="orange"
                        style={{ marginTop: '0.2rem' }}
                      />
                    </>
                  ) : (
                    <>
                      <Label
                        content={'Private'}
                        size="tiny"
                        color={'teal'}
                        style={{ marginTop: '0.2rem' }}
                      />
                    </>
                  )}
                  <hr />
                  {inconsistency.inventory?.vaccine.saleNdc}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {inconsistency.inventory?.vaccine.name}
                  <hr />
                  {inconsistency.inventory?.lot}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </ModalContent>
      <ModalActions>
        <Button primary onClick={() => setOpen(false)}>
          Close
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default BorrowingCurrentCycleClaimListModal;

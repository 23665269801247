import { ReactNode, useCallback, useRef, useState } from 'react';
import { toast } from 'react-semantic-toasts';
import styled from 'styled-components';
import {
  Dimmer,
  Form,
  Header,
  Icon,
  List,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';

import { useApplicationState } from '@bluefox/contexts/ApplicationState';
import { usePractice } from '@bluefox/contexts/Practice';
import { useMutation } from '@apollo/client';
import {
  CreateTicketMutation,
  insertTicketsFiles,
} from '@bluefox/graphql/tickets';
import {
  Ticket,
  TicketDetailType,
  TicketStatusEnum,
  TicketTypes,
} from '@bluefox/models/Tickets';

type Props = {
  children: ReactNode;
};

const FORM_DATA_LIMITS = {
  MAX_FILES: 3,
  MAX_FILE_SIZE: 5 * 1024 * 1024, // 5 MB
};

interface UploadFileData {
  id: string;
  is_uploaded: boolean;
  mime_type: string;
  name: string;
  size: number;
}

interface UploadFileResponse {
  data?: UploadFileData[];
  message: string;
  status: string;
}

const ErrorReportModal = ({ children }: Props) => {
  const { name: practiceName, handler, id: practiceId } = usePractice();
  const { isEmbedded } = useApplicationState();
  const [files, setFiles] = useState<File[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [isFileDraggingOver, setIsFileDraggingOver] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>('');

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { token } = useApplicationState();
  const [createTicket] = useMutation(CreateTicketMutation, {
    onError: (err) => {
      console.error('An error has occurred while creating ticket: ', err);
      toast({
        title: 'An error has occurred while creating ticket.',
        type: 'error',
        time: 3000,
      });
    },
  });

  const [saveTicketsFiles] = useMutation(insertTicketsFiles);

  const validateFiles = (newFiles: File[]): boolean => {
    const errors: string[] = [];
    setFileErrors([]);
    let totalSize = 0;

    if (newFiles.length + files.length > FORM_DATA_LIMITS.MAX_FILES) {
      errors.push(
        `You can only upload up to ${FORM_DATA_LIMITS.MAX_FILES} files.`
      );
    }

    for (const file of newFiles) {
      if (
        !file.type?.includes('image') &&
        !file.type?.includes('application/pdf')
      ) {
        errors.push(
          `The file you are trying to upload must be .jpeg, .png or .pdf`
        );
      }

      if (file.size > FORM_DATA_LIMITS.MAX_FILE_SIZE) {
        errors.push(`File ${file.name} is too large.`);
      }

      totalSize += file.size;
    }

    if (errors.length) {
      setFileErrors(errors);
      return false;
    }

    if (totalSize >= FORM_DATA_LIMITS.MAX_FILE_SIZE) {
      errors.push(`File size exceeds ${FORM_DATA_LIMITS.MAX_FILE_SIZE}`);
    }

    return true;
  };

  const cleanAndClose = () => {
    setOpen(false);
    setErrorDescription('');
    setFileErrors([]);
    setFiles([]);
  };

  const uploadFiles = async () => {
    const fd = new FormData();
    let filesSize = 0;

    files.forEach((file) => {
      filesSize += file.size;
    });

    if (filesSize >= FORM_DATA_LIMITS.MAX_FILE_SIZE) {
      throw new Error(
        `File size exceeds ${FORM_DATA_LIMITS.MAX_FILE_SIZE / 1024 / 1024} MB`
      );
    }

    files.forEach((file: any, index) => {
      fd.append(`file${index}`, file);
    });

    const response = await fetch(
      `${process.env.REACT_APP_API_STORAGE}/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Path': 'tickets',
        },
        body: fd,
        mode: 'cors',
      }
    );

    const jsonData: UploadFileResponse = await response.json();

    if (jsonData.status !== 'success') {
      throw new Error(jsonData.message);
    }

    return jsonData;
  };

  const handleFiles = (selectedFiles: FileList | null) => {
    if (selectedFiles) {
      const newFiles = Array.from(selectedFiles);
      if (validateFiles(newFiles)) {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFiles(e.target.files);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsFileDraggingOver(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsFileDraggingOver(false);
    handleFiles(e.dataTransfer.files);
    e.dataTransfer.clearData();
  };

  const handleInsertTicket = async (ticketDetail: TicketDetailType) => {
    const ticket: Ticket = {
      practiceId,
      type: TicketTypes.BUG_REPORT,
      status: TicketStatusEnum.OPEN,
      detail: ticketDetail,
    };

    return await createTicket({
      variables: { ticket },
    });
  };

  const handleSubmit = useCallback(async () => {
    const ticketDetail = {
      fields: [
        { title: 'Practice', detail: practiceName },
        { title: 'Handler', detail: handler },
        { title: 'Error Description', detail: errorDescription },
      ],
    };

    try {
      setLoading(true);

      if (files.length) {
        const filesData = await uploadFiles();
        const { data: ticketData } = await handleInsertTicket(ticketDetail);

        const ticketsFilesArray = filesData?.data?.map(({ id }) => ({
          fileId: id,
          ticketId: ticketData.insert_ticketing_tickets_one.id,
        }));

        await saveTicketsFiles({
          variables: {
            objects: ticketsFilesArray,
          },
        });
      } else {
        await handleInsertTicket(ticketDetail);
      }

      toast({
        title: 'Ticket Successfully Created',
        type: 'success',
        time: 3000,
      });
    } catch (err: any) {
      console.error(err);
      toast({
        title: err?.message || 'Some error happend',
        type: 'error',
        time: 3000,
      });
    } finally {
      setLoading(false);
      setOpen(false);
      cleanAndClose();
    }
  }, [files, errorDescription]);

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleClose = () => {
    setOpen(false);
    setFileErrors([]);
  };

  return (
    <>
      <Modal
        dimmer="blurring"
        trigger={children}
        centered
        style={{ width: !!isEmbedded ? '70vw' : '40vw' }}
        closeIcon
        open={open}
        onOpen={() => {
          setOpen(true);
          setFiles([]);
        }}
        onClose={handleClose}
      >
        <Modal.Header>Report a problem</Modal.Header>
        <Modal.Content>
          <>
            <Form
              error
              id="bug-report-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Form.TextArea
                required
                label="Describe your problem"
                rows={3}
                value={errorDescription || ''}
                onChange={(_, data) => {
                  setErrorDescription(data.value as string);
                }}
              />

              <p>
                The maximum file size is{' '}
                {FORM_DATA_LIMITS.MAX_FILE_SIZE / 1024 / 1024} MB.
              </p>

              <StyledSegment
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={() => setIsFileDraggingOver(false)}
                onClick={handleFileInputClick}
                hoverable={isFileDraggingOver}
              >
                <Icon name="upload" />
                <Header
                  content="Drag and drop your files here, or click to select files you want to upload. "
                  as="h4"
                />
                <input
                  ref={fileInputRef}
                  type="file"
                  multiple
                  accept="image/*, application/pdf"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                    event.stopPropagation();
                    event.currentTarget.value = '';
                  }}
                />
              </StyledSegment>

              {files.length > 0 && (
                <List>
                  {files.map((file, index) => (
                    <List.Item key={index}>
                      <List.Content floated="right">
                        <Icon
                          name="delete"
                          onClick={() => handleRemoveFile(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      </List.Content>
                      <Icon name="file" />
                      <List.Content>{file.name}</List.Content>
                    </List.Item>
                  ))}
                </List>
              )}

              {!!fileErrors.length && (
                <>
                  {fileErrors.map((error, index) => (
                    <Message error key={index}>
                      {error}
                    </Message>
                  ))}
                </>
              )}
              <Form.Group
                inline
                style={{ justifyContent: 'right', paddingTop: '1rem' }}
              >
                <Form.Button
                  type="button"
                  primary
                  onClick={() => setOpen(false)}
                  basic
                >
                  Close
                </Form.Button>
                <Form.Button type="submit" primary>
                  Report
                </Form.Button>
              </Form.Group>
            </Form>

            {loading && (
              <Dimmer active>
                <Loader>Uploading Ticket...</Loader>
              </Dimmer>
            )}
          </>
        </Modal.Content>
      </Modal>
    </>
  );
};

type StyledSegmentProps = {
  hoverable?: boolean;
};

const StyledSegment = styled(Segment)<StyledSegmentProps>`
  border: ${(props) =>
    props.hoverable
      ? '3px dashed #6f6c6c !important'
      : '2px dashed #ccc !important'};
  border-radius: 5px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export default ErrorReportModal;

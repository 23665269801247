import React, { useEffect, useState } from 'react';
import { PDFPage, PDFDocument, rgb } from 'pdf-lib';
import moment from 'moment-timezone';
import { PatientData, Sex } from '@bluefox/models/Patient';
import { Vaccination, VaccinationRoutes } from '@bluefox/models/Vaccination';
import { Confirm } from 'semantic-ui-react';
import { vaccinationsByDosesCoordsMap } from './vaccinationCoords';
import { Practice } from '@bluefox/models/Practice';
import { formatDateToMMDDYYYYV2 } from '@bluefox/lib/formatters';
import ProviderPicker from '@bluefox/ui/ProviderPicker';
import { useApplicationState } from '@bluefox/contexts';
import PdfDrawerPreviewModal from '@utils/PdfDrawerPreviewModal';

const threeDosesTypes = ['HepA', 'MENING', 'HPV', 'VARICELLA', 'MMR'];

type ExtendedVaccination = Vaccination & { originalType: string };

type IllinoisStatePhysicalFormPdfProps = {
  patientData?: PatientData;
  vaccinations?: Vaccination[];
  practice: Practice;
  pageBlurred?: boolean;
};

const IllinoisStatePhysicalFormPdf = ({
  patientData,
  vaccinations,
  practice,
  pageBlurred,
}: IllinoisStatePhysicalFormPdfProps) => {
  const { isEmbedded } = useApplicationState();

  const [pdfURL, setPdfURL] = useState('');
  const [openPdfPreviewModal, setOpenPdfPreviewModal] = useState(false);
  const [openConfirmSelectedDoctor, setOpenConfirmSelectedDoctor] =
    useState(false);
  const [providerFullName, setProviderFullName] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  //----------- DRAW PATIENT INFO PAGE 1 -----------//
  const drawPatientInfoInPage1 = (
    page: PDFPage,
    patientData: PatientData | undefined
  ) => {
    // Patient's lastName
    page.drawText(patientData ? patientData.lastName : '', {
      x: 38,
      y: 699,
      size: 7,
      color: rgb(0, 0, 0),
    });

    // Patient's firstName
    page.drawText(patientData ? patientData.firstName : '', {
      x: 120,
      y: 699,
      size: 7,
      color: rgb(0, 0, 0),
    });

    // Patient's middleName
    page.drawText(
      patientData && patientData.middleName ? patientData.middleName : '',
      {
        x: 228,
        y: 699,
        size: 7,
        color: rgb(0, 0, 0),
      }
    );

    // Patient's birthdate
    page.drawText(
      patientData ? moment(patientData.birthdate).format('MM/DD/YYYY') : '',
      {
        x: 303,
        y: 699,
        size: 7,
        color: rgb(0, 0, 0),
      }
    );

    // Patient's sex
    page.drawText(
      patientData && patientData.sex === Sex.Male
        ? 'M'
        : patientData && patientData.sex === Sex.Female
        ? 'F'
        : '-',
      {
        x: 377,
        y: 699,
        size: 7,
        color: rgb(0, 0, 0),
      }
    );

    // Patient's address
    page.drawText(
      patientData?.address?.street ? patientData.address.street : '',
      {
        x: 35,
        y: 677,
        size: 7,
        color: rgb(0, 0, 0),
      }
    );

    page.drawText(patientData?.address?.city ? patientData.address.city : '', {
      x: 163,
      y: 675,
      size: 7,
      color: rgb(0, 0, 0),
    });

    page.drawText(patientData?.address?.zip ? patientData.address.zip : '', {
      x: 247,
      y: 674,
      size: 7,
      color: rgb(0, 0, 0),
    });

    // Patient's guardian fullname
    page.drawText(
      patientData && !!patientData.guardians?.length
        ? `${patientData.guardians[0].guardian.lastName}, ${patientData.guardians[0].guardian.firstName}`
        : '',
      {
        x: 296,
        y: 675,
        size: 7,
        color: rgb(0, 0, 0),
      }
    );

    // Patient's guardian phone number
    page.drawText(
      patientData && !!patientData.guardians?.length
        ? patientData.guardians[0].guardian.phone
        : '',
      {
        x: 396,
        y: 675,
        size: 7,
        color: rgb(0, 0, 0),
      }
    );
  };

  //----------- DRAW VACCINATION DOSES -----------//
  const drawDoses = (page: PDFPage, coords: any, givenAt: string) => {
    page.drawText(formatDateToMMDDYYYYV2(givenAt), {
      x: coords.x,
      y: coords.y,
      size: 7.5,
      color: rgb(0, 0, 0),
    });
  };

  const drawTdGroupVaccineComposition = (
    page: PDFPage,
    coords: any,
    type: string
  ) => {
    page.drawText('x', {
      x: coords[type].x,
      y: coords[type].y,
      size: 8,
      color: rgb(0, 0, 0),
    });
  };

  const drawRoute = (page: PDFPage, coords: any) => {
    page.drawText('x', {
      x: coords.route.x,
      y: coords.route.y,
      size: 8,
      color: rgb(0, 0, 0),
    });
  };

  const drawVaccinations = async (
    page: PDFPage,
    vaccinations: Vaccination[] | undefined
  ) => {
    const tdGroupTypes = ['TDAP', 'TD', 'DT'];

    const groupedVaccinationsByType = vaccinations?.reduce(
      (vaccinationsObject, vaccination) => {
        for (const key in vaccination.vaccine?.types) {
          let type = vaccination.vaccine?.types[parseInt(key)] || 0;
          let originalType = type; // We store the original type here

          // If the type is in the TD, TDAP, DT, list, we group them under the "TD_Group" name
          if (tdGroupTypes.includes(String(type))) {
            type = 'TD_Group';
          }

          if (!vaccinationsObject.groupedTypes[type]) {
            vaccinationsObject.groupedTypes[type] = [];
          }

          // If the type already has 6 vaccinations, remove the oldest one (the first element)
          if (vaccinationsObject.groupedTypes[type].length >= 6) {
            vaccinationsObject.groupedTypes[type].shift();
          }

          // If the type is included in the threeDosesTypes array and already has 3 vaccinations, remove the oldest one
          if (
            threeDosesTypes.some((item) => item === type) &&
            vaccinationsObject.groupedTypes[type].length >= 3
          ) {
            vaccinationsObject.groupedTypes[type].shift();
          }

          // Push the current vaccination, including the original type
          vaccinationsObject.groupedTypes[type].push({
            ...vaccination,
            originalType, // We store the original type here
          });
        }

        return vaccinationsObject;
      },
      { groupedTypes: {} } as any
    );

    for (const type in groupedVaccinationsByType.groupedTypes) {
      const vaccinations = groupedVaccinationsByType.groupedTypes[type];

      vaccinations.forEach(
        (vaccination: ExtendedVaccination, index: number) => {
          const givenAt = vaccination.givenAt;

          const vaccinationCoordsByTypes = vaccinationsByDosesCoordsMap[type];

          if (!vaccinationCoordsByTypes) return;

          const pdfCoords: any = vaccinationCoordsByTypes[index];

          drawDoses(
            page,
            pdfCoords,
            vaccination.historic
              ? givenAt.toString()
              : moment(givenAt).format('MM/DD/YYYY')
          );

          if (vaccination.route === VaccinationRoutes.IM && pdfCoords.route)
            drawRoute(page, pdfCoords);

          if (
            (vaccination.vaccine?.types?.[0] === 'TDAP' ||
              vaccination.vaccine?.types?.[0] === 'TD' ||
              vaccination.vaccine?.types?.[0] === 'DT') &&
            (pdfCoords.TDAP || pdfCoords.TD || pdfCoords.DT)
          )
            drawTdGroupVaccineComposition(
              page,
              pdfCoords,
              vaccination.originalType
            );
        }
      );
    }
  };

  //----------- DRAW PATIENT INFO PAGE 2 -----------//
  const drawPatientInfoInPage2 = (
    page: PDFPage,
    patientData: PatientData | undefined
  ) => {
    // Patient's lastName
    page.drawText(patientData ? patientData.lastName : '', {
      x: 49,
      y: 753,
      size: 7,
      color: rgb(0, 0, 0),
    });

    // Patient's firstName
    page.drawText(patientData ? patientData.firstName : '', {
      x: 157,
      y: 753,
      size: 7,
      color: rgb(0, 0, 0),
    });

    // Patient's middleName
    page.drawText(
      patientData && patientData.middleName ? patientData.middleName : '',
      {
        x: 255,
        y: 753,
        size: 7,
        color: rgb(0, 0, 0),
      }
    );

    // Patient's birthdate
    page.drawText(
      patientData ? moment(patientData.birthdate).format('MM/DD/YYYY') : '',
      {
        x: 352,
        y: 758,
        size: 7,
        color: rgb(0, 0, 0),
      }
    );

    // Patient's sex
    page.drawText(
      patientData && patientData.sex === Sex.Male
        ? 'M'
        : patientData && patientData.sex === Sex.Female
        ? 'F'
        : '-',
      {
        x: 416,
        y: 752,
        size: 7,
        color: rgb(0, 0, 0),
      }
    );
  };

  //----------- DRAW PRACTICE INFO PAGE 2 -----------//
  const drawPracticeInfoInPage2 = (page: PDFPage, practiceData: Practice) => {
    // Doctor name
    page.drawText(providerFullName ? providerFullName : '', {
      x: 73,
      y: 56,
      size: 7,
      color: rgb(0, 0, 0),
    });

    // Practice address
    page.drawText(
      `${practiceData.profile.address?.street}, ${practiceData.profile.address?.city}, ${practiceData.profile.address?.state} - ${practiceData.profile.address?.zip}`,
      {
        x: 70,
        y: 42,
        size: 7,
        color: rgb(0, 0, 0),
      }
    );

    // Practice phone number
    page.drawText(
      practiceData.profile.phone?.work
        ? practiceData.profile.phone.work.toString()
        : '',
      {
        x: 469,
        y: 42,
        size: 7,
        color: rgb(0, 0, 0),
      }
    );
  };

  const downloadModifiedPDF = async () => {
    const urlPDF1 = `${process.env.PUBLIC_URL}/forms/illinois/il-state-physical-form-pages-1.pdf`;
    const urlPDF2 = `${process.env.PUBLIC_URL}/forms/illinois/il-state-physical-form-pages-2.pdf`;

    try {
      const pdf1Bytes = await fetch(urlPDF1).then((res) => res.arrayBuffer());
      const pdf2Bytes = await fetch(urlPDF2).then((res) => res.arrayBuffer());

      const pdf1Doc = await PDFDocument.load(pdf1Bytes);
      const pdf2Doc = await PDFDocument.load(pdf2Bytes);

      const pdfDoc = await PDFDocument.create();

      const [page1] = await pdfDoc.copyPages(pdf1Doc, [0]);
      const [page2] = await pdfDoc.copyPages(pdf2Doc, [0]);

      pdfDoc.addPage(page1);
      pdfDoc.addPage(page2);

      drawPatientInfoInPage1(page1, patientData);
      drawVaccinations(page1, vaccinations);
      drawPatientInfoInPage2(page2, patientData);
      drawPracticeInfoInPage2(page2, practice);

      const modifiedPdfBytes = await pdfDoc.save();

      const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
        type: 'application/pdf',
      });

      const downloadUrl = URL.createObjectURL(modifiedPdfBlob);

      // Creates a URL for the preview of the modified PDF file
      return setPdfURL(downloadUrl);
    } catch (error) {
      setError(`Error while processing the PDF. ${error}`);
    }
  };

  return (
    <PdfDrawerPreviewModal
      buttonIcon="download"
      buttonLabel="Physical Form"
      onClickMenuButton={() => setOpenConfirmSelectedDoctor(true)}
      open={openPdfPreviewModal}
      onClose={() => {
        setOpenPdfPreviewModal(false);
        setProviderFullName(undefined);
      }}
      pdfURL={pdfURL}
      isEmbedded={isEmbedded}
      loading={loading}
      error={error}
      pageBlurred={pageBlurred}
    >
      <Confirm
        open={openConfirmSelectedDoctor}
        onCancel={() => setOpenConfirmSelectedDoctor(false)}
        onConfirm={async () => {
          setLoading(true);
          setOpenConfirmSelectedDoctor(false);
          setOpenPdfPreviewModal(true);
          await downloadModifiedPDF();
          setLoading(false);
        }}
        size="mini"
        content={
          <div style={{ margin: '1rem' }}>
            <h3>You can select a prescriber who will authorize this record.</h3>
            <ProviderPicker
              fluid
              prescriber
              placeholder="Select Prescriber"
              onChange={(providerId) => {
                if (!providerId) setProviderFullName(undefined);
              }}
              onProviderSelected={(provider) => {
                setProviderFullName(
                  `${provider.account.lastName}, ${provider.account.firstName}`
                );
              }}
            />
          </div>
        }
      />
    </PdfDrawerPreviewModal>
  );
};

export default IllinoisStatePhysicalFormPdf;

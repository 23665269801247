export const twentyFourHoursFromDate = (date: string | Date) => {
  const initialDate: Date = new Date(date);

  const currentDate: Date = new Date();

  const diffInMiliseconds: number =
    currentDate.getTime() - initialDate.getTime();

  const hoursFromInitialDate: number = diffInMiliseconds / (1000 * 60 * 60);

  if (hoursFromInitialDate > 24) {
    return true;
  } else {
    return false;
  }
};

import { Button, Form, Modal } from 'semantic-ui-react';

export type FormValues = {
  showVfc?: boolean;
  showPrivate?: boolean;
};

type Props = {
  formValues?: FormValues;
  setFormValues?: React.Dispatch<React.SetStateAction<FormValues | undefined>>;
  open: boolean;
  onSubmit?: (formValues?: FormValues) => void;
  onCancel?: () => void;
};

const InventoryPrintSavePdfModal = ({
  formValues,
  setFormValues,
  open,
  onSubmit,
  onCancel,
}: Props) => {
  return (
    <Modal closeIcon onClose={onCancel} open={open} size="tiny">
      <Modal.Header>Inventory List - Print / Save as PDF </Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <p>
            Please select which type(s) of inventory list you would like to
            include:
          </p>
          <Form.Checkbox
            label="Show private inventories"
            checked={formValues?.showPrivate}
            onChange={(_, { checked }) => {
              setFormValues?.((prevValues) => ({
                ...prevValues,
                showPrivate: checked,
              }));
            }}
          />
          <Form.Checkbox
            label="Show VFC inventories"
            checked={formValues?.showVfc}
            onChange={(_, { checked }) => {
              setFormValues?.((prevValues) => ({
                ...prevValues,
                showVfc: checked,
              }));
            }}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary type="button" content="Cancel" onClick={onCancel} />
        <Button
          primary
          disabled={!formValues?.showPrivate && !formValues?.showVfc}
          type="submit"
          content="Print / Save PDF"
          icon="save"
          onClick={() => onSubmit?.(formValues)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default InventoryPrintSavePdfModal;

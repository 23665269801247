import React from 'react';
import { Label, Table } from 'semantic-ui-react';
import {
  VFCInconsistency,
  VFCInconsistencyStatuses,
} from '@bluefox/models/VFCInconsistency';
import { formatDatetimeToMMDDYYY } from '@bluefox/lib/formatters';
import BorrowingRedundantWithBox from './BorrowingRedundantWithBox';

interface BorrowingSummaryDetailClaimRowProps {
  inconsistency: VFCInconsistency;
  sourceTarget: 'source' | 'target';
}

const BorrowingSummaryDetailClaimRow = (
  props: BorrowingSummaryDetailClaimRowProps
) => {
  const { inconsistency } = props;

  return (
    <Table.Row key={inconsistency.id}>
      <Table.Cell textAlign="center">
        {`${inconsistency.claim?.practicePatient.patientData
          .firstName} ${inconsistency.claim?.practicePatient.patientData
          .lastName} (${
          inconsistency.claim?.practicePatient.patientData.birthdate
            ? formatDatetimeToMMDDYYY(
                inconsistency.claim?.practicePatient.patientData.birthdate
              )
            : '-'
        })`}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.claim?.insurance[0]?.insuranceCompanyName}{' '}
        <Label
          content={
            inconsistency.claim?.insurance[0]?.vfcEligible
              ? 'VFC Eligible'
              : 'Private'
          }
          size="tiny"
          color={
            inconsistency.claim?.insurance[0]?.vfcEligible ? 'orange' : 'teal'
          }
        />
        <Label
          basic
          size="tiny"
          content={`Member ID: ${
            inconsistency.claim?.insurance[0]?.memberId || '-'
          }`}
          style={{ marginTop: '0.2rem' }}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.claim?.givenAt
          ? formatDatetimeToMMDDYYY(inconsistency.claim?.givenAt).replaceAll(
              '-',
              '/'
            )
          : '-'}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.inventory?.vfc ? (
          <>
            <Label
              content="VFC Eligible"
              size="tiny"
              color="orange"
              style={{ marginTop: '0.2rem' }}
            />
          </>
        ) : (
          <>
            <Label
              content={'Private'}
              size="tiny"
              color={'teal'}
              style={{ marginTop: '0.2rem' }}
            />
          </>
        )}
        <hr />
        {inconsistency.inventory?.vaccine.saleNdc}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.inventory?.vaccine.name}
        <hr />
        {inconsistency.inventory?.lot}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {inconsistency.status === VFCInconsistencyStatuses.REDUNDANT && (
          <BorrowingRedundantWithBox
            redundantWith={inconsistency.redundantWith}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default BorrowingSummaryDetailClaimRow;
